import "bootstrap/dist/css/bootstrap.min.css";
import NewsPage from "./pages/NewsPage";
import "./css/custom.css";
import "react-toastify/dist/ReactToastify.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import SingleNews from "./pages/SingleNews";

import InvestorePage from "./pages/InvestorePage";
import CareerPage from "./pages/CareerPage";
import AssistantManagerPage from "./pages/AssistantManagerPage";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import ContactUsPage from "./pages/ContactUsPage";
import ProductPage from "./pages/ProductPage";
import ServicePage from "./pages/ServicePage";
import Responsibility from "./pages/Responsibility";
import TreatmentsPage from "./pages/TreatmentsPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Blogs from "./pages/Blogs";
import BlogDetails from "./pages/BlogDetails";
import General from "./pages/General";
import Human from "./pages/Human";
import Investor from "./pages/Investor";
import Event from "./pages/Event";
import Reports from "./pages/Reports";
import "./css/Responsiveness.css";
import "./css/report.css";



function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/news" element={<NewsPage />} />
        <Route path="/news-single" element={<SingleNews />} />
        <Route path="/Investors" element={<InvestorePage />} />
        <Route path="/career" element={<CareerPage />} />
        <Route path="/career-single" element={<AssistantManagerPage />} />
        <Route path="/about-us" element={<AboutPage />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/general" element={<General />} />
        <Route path="/human" element={<Human />} />
        <Route path="/investor" element={<Investor />} />
        <Route path="/report" element={<Reports />} />
        <Route path="/event" element={<Event />} />
        <Route path="/products" element={<ProductPage />} />
        <Route path="/treatments" element={<ServicePage />} />
        <Route path="/responsibility" element={<Responsibility />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/Blogs" element={<Blogs />} />
        <Route path="/BlogDetails" element={<BlogDetails />} />
        {/* <Route path="/treatments" element={<TreatmentsPage />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;

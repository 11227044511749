import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import FooterFirst from "../components/footer/FooterFirst";
import NavbarFirst from "../components/navs/Navbar";
import NewInfo1 from "../components/news/NewInfo1";
import NewsCard from "../components/news/NewsCard";
import NewsHero from "../components/news/NewsHero";
import NewsSingleImg from "../components/news/NewsSingleImg";
import heroimage from "./../assets/images/hero-news.png";

import Aos from "aos";
import "aos/dist/aos.css";
import GoToTopButton from "../components/footer/GoToTopButton";
import { useLocation } from "react-router-dom";
function SingleNews() {
  useEffect(() => {
    Aos.init({});
  }, [1000]);

  const [data, setData] = useState();
  const [title, setTitle] = useState();
  const [imgBlog, setImgBlog] = useState();
  const [description, setDescription] = useState();
  const { state } = useLocation();

  useEffect(() => {
    setData(state);
    setTitle(state?.ele?.title);
    setImgBlog(state?.ele?.image);
    setDescription(state?.ele?.description);
  });
  console.log(data, "data");
  console.log(title, "title");
  console.log(imgBlog, "imgBlog");
  console.log(description, "description");

  useEffect(() => {
    window.scroll();
  }, [data]);
  return (
    <>
      <NavbarFirst />
      <div className="herodiv">
        <img
          src={heroimage}
          alt="hero Img"
          width="100%"
          className="img-fluid"
        />
        <div class="aboutus-div">
          <div className="conatiner">
            <h2 className="hero-text fs-1 font2"> Press Release</h2>
          </div>
        </div>
      </div>
      <div>
        <div className="container mt-4">
          <div className="row">
            <div className="col-md-12 ">
              <span className=" text-light fs-5"> Home | </span>
              <span className="text-primary fs-5"> Press Release </span>
            </div>
          </div>
        </div>
      </div>{" "}
      {/* <div className="container" data-aos="fade-up" data-aos-duration="3000">
        <div className="row my-3 mt-5">
          <h2 className=" my-3">
            <b>{title} </b>
          </h2>
        </div>
      </div> */}
      <div className="container" data-aos="fade-up" data-aos-duration="3000">
        <div className="row my-3 mt-5">
          <div className="col-md-12">
            <img src={imgBlog} alt=" Img " className="img-fluid w-75" />
          </div>
        </div>
        <div className="row my-3 mt-5">
          {" "}
          <div className=" my-3 mt-5">
            <h2 className=" my-3">
              <b>{title} </b>
            </h2>
          </div>
          <div
            className="col-md-12"
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
        </div>
      </div>
      <div className="my-3 py-3">
        <FooterFirst />
      </div>
      <GoToTopButton />
    </>
  );
}

export default SingleNews;

import React from "react";
import { Image, Spinner } from "react-bootstrap";
import FooterFirst from "../components/footer/FooterFirst";
import NavbarFirst from "../components/navs/Navbar";
import PageHistory from "../components/navs/PageHistory";
import abouthero from "../assets/images/contactus-hero.png";
import icon1 from "../assets/images/contact-icon.png";
import icon2 from "../assets/images/contact-icon2.png";
import icon3 from "../assets/images/contact-icon3.png";
import icon4 from "../assets/images/contact-icon4.png";
import arrow_right from "../assets/images/arrow_right_white.png";
import { Form } from "react-bootstrap";
import GoToTopButton from "../components/footer/GoToTopButton";
import { useState } from "react";
import baseURL from "../service/Url";
import axios from "axios";
import ApiService from "../service/ApiService";
import { ToastContainer, toast, Zoom } from "react-toastify";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const ContactUsPage = () => {
  /** Particulat postion **/
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(location?.state?.one, location?.state?.two);
  }, [location?.state?.two]);

  const [loadstate, setloadstate] = useState(false);
  const [formFields, setFormFields] = useState({
    fullName: "",
    email: "",
    number: "",
    message: "",
  });

  const [formError, setFormError] = useState({
    fullName: "",
    email: "",
    number: "",
    message: "",
  });

  const handleValidation = () => {
    // const regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    const regText = /[A-Za-z]/;
    const rangEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const newErros = {};
    const { fullName, email, number, message } = formFields;
    if (!fullName) {
      newErros.fullName = "Please Enter Full Name";
    } else if (fullName && !regText.test(fullName)) {
      newErros.fullName = "Full Name should be text";
    } else if (fullName && fullName.length > 50) {
      newErros.fullName = "FullName should be below 50 charecters";
    }

    if (!email) {
      newErros.email = "Please Enter Email";
    } else if (email && !rangEmail.test(email)) {
      newErros.email = "Please Enter Corect Email";
    }

    if (!number) {
      newErros.number = "please enter mobile number";
    } else if (number && number.length != 10) {
      newErros.number = "Mobile Number should be 10 digits";
    }

    if (!message) {
      newErros.message = "Please enter Message";
    } else if (message && message.length > 30) {
      newErros.message = "Message should be below 30 charecters";
    }

    return newErros;
  };

  const handleOnChange = (e) => {
    const p = { ...formFields };
    p[e.target.name] = e.target.value;
    setFormFields(p);

    setFormError({
      ...formError,
      [e.target.name]: null,
    });
  };

  const handelSubmit = async (e) => {
    const handleValidationObject = handleValidation();
    if (Object.keys(handleValidationObject).length > 0) {
      setFormError(handleValidationObject);
    } else {
      try {
        setloadstate(true);

        const payload = {
          name: formFields.fullName,
          email: formFields.email,
          mobile_no: formFields.number,
          description: formFields.message,
          category: "contactus",
        };

        const addContact = await ApiService.postData("/create_user", payload);
        setloadstate(false);

        if (!addContact.err) {
          const arr = { ...formFields };
          arr.fullName = "";
          arr.email = "";
          arr.number = "";
          arr.message = "";
          setFormFields(arr);
          toast.success("Success");
        } else {
          toast.error(addContact.err, "Please Fill Up All Fields");
        }
      } catch (err) {
        toast.error(err.message, "Please Fill Up All Fields");
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Eugia Pharma - Contact Us</title>
        <meta
          name="description"
          content="Connect with us via email, We would love to hear from you!"
        />
      </Helmet>

      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        transition={Zoom}
        delay={1000}
        limit={1}
      />
      <div>
        <NavbarFirst />

        <div className="herodiv">
          <img src={abouthero} alt="hero Img" width="100%" />
          <div class="aboutus-div">
            <div className="conatiner">
              <h2 className="hero-text fs-1 font2"> Contact Us</h2>
            </div>
          </div>
        </div>
        <PageHistory Text="Contact Us" />
        <div className="container">
          <div className="row">
            <div className="col-md-12 my-5">
              <h2 className="contact-text my-5">
                {/* We hope you like our products but if you don’t and have
                complaints regarding the same then shoot us an email or fill in
                this form. */}
                <span style={{ marginLeft: "400px" }}>
                  We would love to hear from you!
                </span>
                <br></br>
                {/* Whether it’s a few words of praise or valuable feedback that can help us improve,
                don’t hesitate to reach out to us via email or fill out the form below! */}
              </h2>
            </div>
          </div>
        </div>

        <div className="contact-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-6 ">
                <div className="row my-3  pt-5">
                  <div className="col-md-2">
                    <Image src={icon3} className="img-fluid " />
                  </div>
                  <div className="col-md-8">
                    <Link to="/general">
                      <h5 className="text-white  font1 my-1">
                        <b>General Enquiry</b> <Image src={arrow_right} style={{width: "40px",height:"40px",cursor: "pointer"}} />
                      </h5>
                      <p className="text-white  font1 my-2">
                        <a href="mailto:info@eugia.co.in " className="text-white">
                          info@eugiapharma.com
                        </a>
                      </p>
                    </Link>
                  </div>
                </div>
                <div className="row my-3 pt-5">
                  <div className="col-md-2">
                    <Image src={icon1} className="img-fluid " />
                  </div>
                  <div className="col-md-8">
                    <Link to="/human">
                    <h5 className="text-white  font1 my-1">
                      <b> Human Resources</b> <Image src={arrow_right} style={{width: "40px",height:"40px",cursor: "pointer"}}
                       />
                    </h5>

                    <p className="text-white  font1 my-2">
                      <a
                        href="mailto:eugiacareers@eugia.co.in"
                        className="text-white"
                      >
                        eugiacareers@eugiapharma.com
                      </a>
                    </p>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-5">
                <div className="row my-3  pt-5">
                  <div className="col-md-2">
                    <Image src={icon2} className="img-fluid " />
                  </div>
                  <div className="col-md-8">
                    <Link to="/investor">
                    <h5 className="text-white  font1 my-2">
                      <b> Investor Relations</b> <Image src={arrow_right} style={{width: "40px",height:"40px",cursor: "pointer"}} />
                    </h5>

                    <p className="text-white  font1 my-1">
                      <a href="mailto:IR@eugia.co.in" className="text-white">
                        IR@eugiapharma.com
                      </a>
                    </p>
                    </Link>
                  </div>
                </div>
                <div className="row my-3  pt-5">
                  <div className="col-md-2">
                    <Image src={icon4} className="img-fluid report-image" />
                  </div>
                  <div className="col-md-8">
                    <Link to="/event">
                    <h5 className="text-white  font1 my-2">
                      <b>Adverse Event Reporting</b> <Image src={arrow_right} style={{width: "40px",height:"40px",cursor: "pointer"}} />
                    </h5>

                    <p className="text-white  font1 my-1">
                      <a href="mailto:pharmacovigilance@eugiapharma.com" className="text-white">
                        pharmacovigilance@eugiapharma.com
                      </a>
                    </p>
                    </Link>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6 px-5">
                <div className="px-5">
                  <input
                    type="text"
                    className="form-control contact-input mt-5"
                    placeholder="Full Name"
                    onChange={handleOnChange}
                    name="fullName"
                    value={formFields.fullName}
                  />
                  <span className="text-danger">{formError.fullName}</span>
                  <input
                    type="text"
                    className="form-control contact-input  mt-5"
                    placeholder="Email address"
                    onChange={handleOnChange}
                    name="email"
                    value={formFields.email}
                  />
                  <span className="text-danger">{formError.email}</span>
                  <input
                    type="number"
                    className="form-control contact-input  mt-5"
                    placeholder="Phone Number"
                    onChange={handleOnChange}
                    name="number"
                    value={formFields.number}
                    id="numbers-input"
                    maxLength={10}
                    onInput={(e) => {
                      if(e.target.value.length > e.target.maxLength){
                        e.target.value = e.target.value.slice(0,e.target.maxLength)
                      }
                    }}
                  />
                  <span className="text-danger">{formError.number}</span>
                  <div className="mt-5 pt-5">
                    <input
                      type="text"
                      className="form-control contact-input  mt-5"
                      placeholder="Type your message here"
                      onChange={handleOnChange}
                      name="message"
                      value={formFields.message}
                    />
                    <span className="text-danger">{formError.message}</span>
                  </div>
                  <div className="mt-5 text-center">
                    <button
                      type="button"
                      className="btn bg-secondary text-white py-2 rounded-0 mt-5 text-center"
                      onClick={handelSubmit}
                    >
                      SEND A MESSAGE
                      {loadstate ? (
                        <Spinner
                          animation="border"
                          variant="Primary"
                          size="sm"
                        />
                      ) : (
                        ""
                      )}
                    </button>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        {/* ------------------------- */}
        <div className="contact-bg">
          <div className="container py-4">
            {/* <div className="row ">
              <div className="col-md-4 my-3 m">
                <input
                  type="text"
                  class="form-control contact-input"
                  placeholder="Full Name"
                  onChange={handleOnChange}
                  name="fullName"
                  value={formFields.fullName}
                />
                <span className="text-danger">{formError.fullName}</span>
              </div>
              <div className="col-md-4 my-3">
                <input
                  type="text"
                  class="form-control contact-input"
                  placeholder="Email address"
                  onChange={handleOnChange}
                  name="email"
                  value={formFields.email}
                />
                <span className="text-danger">{formError.email}</span>
              </div>
              <div className="col-md-4 my-3">
                <input
                  type="number"
                  class="form-control contact-input"
                  placeholder="Phone Number"
                  onChange={handleOnChange}
                  name="number"
                  value={formFields.number}
                />
                <span className="text-danger">{formError.number}</span>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12 my-3 mt-5">
                <input
                  type="text"
                  class="form-control contact-input"
                  placeholder="Type your message here"
                  onChange={handleOnChange}
                  name="message"
                  value={formFields.message}
                />
                <span className="text-danger">{formError.message}</span>
              </div>
            </div> */}

            {/* <div className="row ">
              <div className="col-md-12 text-center my-5">
                <button
                  type="button"
                  class="btn bg-secondary text-white py-2 rounded-0"
                  onClick={handelSubmit}
                >
                  SEND A MESSAGE
                  {loadstate ? (
                    <Spinner animation="border" variant="Primary" size="sm" />
                  ) : (
                    ""
                  )}
                </button>
              </div>
            </div> */}
            {/* <div className="row   my-3">
              <div className="col-md-4 ">
             
                <div className="border-bottom boxcontact">
                  <Image src={icon1} className="img-fluid imgiconcontact" />
                  <h5 className="text-white mt-3 mb-1 font1">
                    <b> Human Resources</b>
                  </h5>
                  <span className="fs-6 text-white font1">
                    For job opportunities
                  </span>
                  <p className="text-white pt-4 mt-5 mb-3 pb-3 font1"> <b>E
                  </b>  <a
                    href="mailto:eugiacareers@eugia.co.in"
                    className="text-white"
                  >
                      eugiacareers@eugia.co.in
                    </a></p>
                </div>


              </div>
              <div className="col-md-4">

                <div className="border-bottom boxcontact">
                  <Image src={icon2} className="img-fluid imgiconcontact" />
                  <h5 className="text-white mt-3 mb-1 font1">
                    <b>Investor Relations</b>
                  </h5>
                  <span className="fs-6 text-white font1">
                    For investor queries


                  </span>
                  <p className="text-white pt-4 mt-5 mb-3 pb-3 font1"><b>E
                  </b>  <a
                    href="mailto:eugiacareers@eugia.co.in"
                    className="text-white"
                  >
                      IR@eugia.co.in
                    </a></p>
                </div>

              </div>
              <div className="col-md-4">

                <div className="border-bottom boxcontact">
                  <Image src={icon3} className="img-fluid imgiconcontact" />
                  <h5 className="text-white mt-3 mb-1 font1">
                    <b> Corporate Communication</b>
                  </h5>
                  <span className="fs-6 text-white font1">
                    For media queries
                  </span>
                  <p className="text-white pt-4 mt-5 mb-3 pb-3 font1"><b>E
                  </b>  <a
                    href="mailto:eugiacareers@eugia.co.in"
                    className="text-white"
                  >
                      bevocal@eugia.co.in
                    </a></p>
                </div>

              </div>
            </div> */}
          </div>
        </div>

        <div className="my-5 ">
          <div className="my-2">
            <FooterFirst />
          </div>
        </div>
        <GoToTopButton />
      </div>
    </>
  );
};

export default ContactUsPage;

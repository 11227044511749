import React, { useEffect, useState } from "react";
import FooterFirst from "../components/footer/FooterFirst";
import NavbarFirst from "../components/navs/Navbar";
import heroimage from "../assets/images/career-hero.png";
import { Button, Card, Form, Image, Modal, Spinner } from "react-bootstrap";
import image1 from "./../assets/images/career-img-1.png";
import image2 from "./../assets/images/career-img-2.png";
import arrow from "./../assets/images/arrow.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import PageHistory from "../components/navs/PageHistory";
import GoToTopButton from "../components/footer/GoToTopButton";
import ApiService from "../service/ApiService";
import { ToastContainer, toast, Zoom } from "react-toastify";
import axios from "axios";
import baseURL from "../service/Url";
import { Helmet } from "react-helmet";

function CareerPage() {
  /** Particulat postion **/
  const location = useLocation();
  window.scrollTo(location?.state?.one, location?.state?.two);

  useEffect(() => {
    Aos.init({});
  }, [1000]);

  const [showIntern, setShowiIntern] = useState(false);
  const [showAnyone, setShowAnyone] = useState(false);
  const [loader, setLoader] = useState(false);

  const [showjoin, setShowjoin] = useState(false);

  const [data, dataSet] = useState([]);
  const des = data.slice(0, 10);
  useEffect(() => {
    async function fetchMyAPI() {
      try {
        let response = await ApiService.getData("/category/career");

        if (!response.error) {
          dataSet(response);
        } else {
          console.log(" Blog API Not Working");
          toast.error("Blog API Not Working");
        }
      } catch (err) {
        console.error(err.message);
        toast.error(err.message);
      }
    }

    fetchMyAPI();
  }, []);

  const navigate = useNavigate();

  const handleSendData = (ele) => {
    navigate("/career-single", {
      state: {
        ele,
      },
    });
  };

  const [formFields, setFormFields] = useState({
    fullName: "",
    email: "",
    file: "",
    description: "",
  });

  const handleOnChange = (e) => {
    const p = { ...formFields };
    p[e.target.name] = e.target.value;
    setFormFields(p);
  };

  const handelSubmit = async (e) => {
    try {
      const payload = {
        name: formFields.fullName,
        email: formFields.email,
        description: formFields.description,
        file: formFields.file,
        category: "contactus",
      };

      const addContact = await ApiService.postData("/create_user", payload);

      if (!addContact.err) {
        const arr = { ...formFields };
        arr.fullName = "";
        arr.email = "";
        arr.description = "";
        arr.file = "";
        setFormFields(arr);
        toast.success("Success");
      } else {
        toast.error(addContact.err, "Please Fill Up All Fields");
      }
    } catch (err) {
      toast.error(err.message, "Please Fill Up All Fields");
    }
  };

  const handleInternShow = () => {
    setShowiIntern(true);
  };
  const handleInternClose = () => {
    setShowiIntern(false);
  };

  const handleInternClear = () => {
    setInternForm({
      ...internForm,
      name: "",
      email: "",
      mobile_no: "",
      description: "",
      image: "",
    });
  };

  const handleAnyoneShow = () => {
    setShowAnyone(true);
  };

  const handleAnyoneClose = () => {
    setShowAnyone(false);
  };

  const handleAnyoneClear = () => {
    setAnyoneForm({
      ...anyoneForm,
      name: "",
      email: "",
      mobile_no: "",
      description: "",
      image: "",
    });
  };

  /***  handle Intern with us form ***/
  const [internForm, setInternForm] = useState({
    name: "",
    email: "",
    mobile_no: "",
    description: "",
    image: "",
  });

  const [internFormErrors, setInternFormErrors] = useState({
    name: "",
    email: "",
    mobile_no: "",
    description: "",
    image: "",
  });

  const [file, setFile] = useState({
    image: "",
  });
  const [fileErrors, setFileErrors] = useState({
    image: "",
  });

  const handleChangeIntern = (event) => {
    if (event.target.name == "image") {
      setFile({
        ...file,
        [event.target.name]: event?.target?.files[0],
      });
      setFileErrors({
        ...file,
        image: "",
      });
    } else {
      setInternForm({
        ...internForm,
        [event.target.name]: event.target.value,
      });
    }
    setInternForm({
      ...internForm,
      [event.target.name]: event.target.value,
    });
    setInternFormErrors({
      ...internFormErrors,
      [event.target.name]: null,
    });
  };

  const handleInternValidation = () => {
    const { name, email, mobile_no, description, image } = internForm;
    const newErrors = {};
    const regText = /[A-Za-z]/;
    const regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (!name) {
      newErrors.name = "please enter name";
    } else if (name && !regText.test(name)) {
      newErrors.name = "name should be text";
    } else if (name && name.length > 50) {
      newErrors.name = "name should be below 50 charecters";
    }

    if (!email) {
      newErrors.email = "please enter name";
    } else if (email && !regEmail.test(email)) {
      newErrors.email = "please enter proper email";
    }

    if (!mobile_no) {
      newErrors.mobile_no = "please enter mobile number";
    } else if (mobile_no && mobile_no.length != 10) {
      newErrors.mobile_no = "Mobile Number should be 10 digits";
    }

    if (!description) {
      newErrors.description = "please enter description";
    }

    if (!image) {
      newErrors.image = "please select file";
    }
    return newErrors;
  };

  const handleInternSubmit = () => {
    const handleInternValidationObject = handleInternValidation();
    if (Object.keys(handleInternValidationObject).length > 0) {
      setInternFormErrors(handleInternValidationObject);
    } else {
      setLoader(true);
      let formData = new FormData();
      formData.append("name", internForm.name);
      formData.append("email", internForm.email);
      formData.append("mobile_no", internForm.mobile_no);
      formData.append("description", internForm.description);
      formData.append("image", file.image);
      formData.append("category", "intern");

      axios({
        url: `${baseURL}/create_user`,
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.status == 200) {
            toast.success("Successfully Created");
            setLoader(false);
            setInternForm({
              ...internForm,
              name: "",
              email: "",
              mobile_no: "",
              description: "",
              image: "",
            });
            setFile({
              ...file,
              image: "",
            });
            setInternFormErrors({
              ...internFormErrors,
              name: "",
              email: "",
              mobile_no: "",
              description: "",
              image: "",
            });
          }
        })
        .catch((err) => {
          if (err.response.status == 401) {
            toast.error("Token is Expaired");
            setLoader(false);
            setInternForm({
              ...internForm,
              name: "",
              email: "",
              mobile_no: "",
              description: "",
              image: "",
            });
            setInternFormErrors({
              ...internFormErrors,
              name: "",
              email: "",
              mobile_no: "",
              description: "",
              image: "",
            });
            setFile({
              ...file,
              image: "",
            });
          }
        });
    }
  };

  /** hnadle Apply Any One **/
  const [anyoneForm, setAnyoneForm] = useState({
    name: "",
    email: "",
    mobile_no: "",
    description: "",
    image: "",
  });

  const [anyoneFormErrors, setAnyoneFormErrors] = useState({
    name: "",
    email: "",
    mobile_no: "",
    description: "",
    image: "",
  });

  const [file1, setFile1] = useState({
    image: "",
  });
  const [fileErrors1, setFileErrors1] = useState({
    image: "",
  });

  const handleChangeAnyone = (event) => {
    if (event.target.name == "image") {
      setFile1({
        ...file1,
        [event.target.name]: event?.target?.files[0],
      });
      setFileErrors1({
        ...file1,
        image: "",
      });
    } else {
      setAnyoneForm({
        ...anyoneForm,
        [event.target.name]: event.target.value,
      });
    }
    setAnyoneForm({
      ...anyoneForm,
      [event.target.name]: event.target.value,
    });
    setAnyoneFormErrors({
      ...anyoneFormErrors,
      [event.target.name]: null,
    });
  };

  const handleAnyoneValidation = () => {
    const { name, email, mobile_no, description, image } = anyoneForm;
    const newErrors = {};
    const regText = /[A-Za-z]/;
    const regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (!name) {
      newErrors.name = "please enter name";
    } else if (name && !regText.test(name)) {
      newErrors.name = "name should be text";
    } else if (name && name.length > 50) {
      newErrors.name = "name should be below 50 charecters";
    }

    if (!email) {
      newErrors.email = "please enter name";
    } else if (email && !regEmail.test(email)) {
      newErrors.email = "please enter proper email";
    }

    if (!mobile_no) {
      newErrors.mobile_no = "please enter mobile number";
    } else if (mobile_no && mobile_no.length != 10) {
      newErrors.mobile_no = "mobile number should be 10 digits";
    }

    if (!description) {
      newErrors.description = "please enter description";
    }

    if (!image) {
      newErrors.image = "please select file";
    }
    return newErrors;
  };

  const handleAnyoneSubmit = () => {
    const handleAnyoneValidationObject = handleAnyoneValidation();
    if (Object.keys(handleAnyoneValidationObject).length > 0) {
      setAnyoneFormErrors(handleAnyoneValidationObject);
    } else {
      setLoader(true);
      let formData = new FormData();
      formData.append("name", anyoneForm.name);
      formData.append("email", anyoneForm.email);
      formData.append("mobile_no", anyoneForm.mobile_no);
      formData.append("description", anyoneForm.description);
      formData.append("image", file1.image);
      formData.append("category", "anyoneapply");

      axios({
        url: `${baseURL}/create_user`,
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.status == 200) {
            toast.success("Successfully Created");
            setLoader(false);
            setAnyoneForm({
              ...anyoneForm,
              name: "",
              email: "",
              mobile_no: "",
              description: "",
              image: "",
            });
            setFile1({
              ...file1,
              image: "",
            });
            setAnyoneFormErrors({
              ...anyoneFormErrors,
              name: "",
              email: "",
              mobile_no: "",
              description: "",
              image: "",
            });
          }
        })
        .catch((err) => {
          if (err.response.status == 401) {
            toast.error("Token is Expaired");
            setLoader(false);
            setAnyoneForm({
              ...anyoneForm,
              name: "",
              email: "",
              mobile_no: "",
              description: "",
              image: "",
            });
            setAnyoneFormErrors({
              ...anyoneFormErrors,
              name: "",
              email: "",
              mobile_no: "",
              description: "",
              image: "",
            });
            setFile1({
              ...file1,
              image: "",
            });
          }
        });
    }
  };

  return (
    <>
      <Helmet>
        <title>Eugia Pharma - Career</title>
        <meta
          name="description"
          content="Eugia always has space for those who want to use their talent & knowledge to push boundaries in the medical & pharmaceutical fields. Check out our current vacancies"
        />
      </Helmet>

      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar
        transition={Zoom}
        delay={500}
        limit={1}
      />
      <NavbarFirst />

      <div className="herodiv">
        <img
          src={heroimage}
          alt="hero Img"
          width="100%"
          className="img-fluid"
        />
        <div class="aboutus-div">
          <div className="conatiner">
            <h2 className="hero-text fs-1 font2">Careers</h2>
          </div>
        </div>
      </div>
      <PageHistory Text="Careers" />
      <div className="container">
        <div className="row my-4">
          <div className="col-md-7 careerdiv">
            <Image src={image1} className="w-100 pr-4 img-fluid" />
          </div>
          <div className="col-md-5 my-3 px-5 careerdiv">
            <div className="border-investore"></div>
            <h3 className="fs-2 carrertext font-weight-bold py-3 font2 text-primary">
              Apply your technical and medical expertise here and benefit
              humankind
            </h3>
            <p className="py-2 font1 ">
              Eugia always has space for those who want to use their talent and
              knowledge to push boundaries in the medical and pharmaceutical
              fields. If you think you can contribute to the future of pharma,
              join us and help redefine the future.
            </p>
          </div>
        </div>
      </div>
      <div className="container ">
        <div className="row my-5 py-4 Current">
          <div className="col-md-12">
            <h5 className="text-uppercase text-center text-primary  font2 display-5">
              Current Vacancies
            </h5>
          </div>
        </div>

        <div className="row card-container ">
          {data.map((ele) => (
            <>
              <div className="col-md-4 mb-5 careerdiv">
                <div class="card">
                  <div class="card-body bg-light">
                    <div className="">
                      <div ctlassName="m-2 ">
                        <h5 className="footer-h font1">
                          {" "}
                          {ele?.title.slice(0, 37)}..{" "}
                        </h5>
                      </div>
                      <hr className="hr-2" />
                      <div>
                        <p className="fs-6 font1">
                          <b> Qualification : </b>
                          {ele?.qualification}{" "}
                        </p>
                        <p className="fs-6 font1">
                          <b> Experience : </b>
                          {ele?.exp}{" "}
                        </p>
                        <p className="fs-6 font1">
                          <b> Job Location : </b>
                          {ele?.job_location}{" "}
                        </p>

                        <p
                          dangerouslySetInnerHTML={{
                            __html: ele?.description.slice(0, 150),
                          }}
                          className="font1"
                        ></p>
                      </div>

                      <div className="">
                        <p
                          className="learn-more text-secondary mt-3"
                          onClick={() => handleSendData(ele)}
                        >
                          <Link> KNOW MORE</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </>
          ))}
        </div>

        <div className="d-flex internal">
          <div className="careerimg1 text-white internal">
            <div className="p-5">
              <h2 className="font2">Intern with us</h2>
              <p className="font1">
                Discipline and dedication have the power to shape a successful
                career. If you have the will to exceed and the passion to make
                lives better, join our internship program for a comprehensive
                and hands-on experience.
              </p>
              <div className=" mt-5 cursor " onClick={handleInternShow}>
                <span className="border p-2 px-4 my-3">
                  {" "}
                  <b>Apply </b>{" "}
                </span>
              </div>
            </div>
          </div>
          <div className="col-1 internal"></div>
          <div className="careerimg2 text-white internal">
            <div className="p-5">
              <h2 className="font2">Anyone can apply</h2>
              <p className="font1">
                We believe opportunities are created, not found.
                <br /> If you can’t see a suitable job for your profile,
                <br /> don’t fret. Submit Your Profile.
                <br /> we will consider you
              </p>
              <div
                className=" mt-5 cursor divintern"
                onClick={handleAnyoneShow}
              >
                <span className="border p-2 px-4 my-3">
                  <b>Apply </b>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex applydiv">
          <div className="careerimg1 text-white mb-5">
            <div className="p-5">
              <h2 className="font2">Intern with us</h2>
              <p className="font1">
                Discipline and dedication have the power to shape a successful
                career. If you have the will to exceed and the passion to make
                lives better, join our internship program for a comprehensive
                and hands-on experience.
              </p>
              <div className=" mt-5 cursor " onClick={handleInternShow}>
                <span className="border p-2 px-4 my-3">
                  {" "}
                  <b>Apply </b>{" "}
                </span>
              </div>
            </div>
          </div>
          <div className="col-1"></div>
          <div className="careerimg2 text-white">
            <div className="p-5">
              <h2 className="font2">Anyone can apply</h2>
              <p className="font1">
                We believe opportunities are created, not found.
                <br /> If you can’t see a suitable job for your profile,
                <br /> don’t fret. Submit Your Profile.
                <br /> we will consider you
              </p>
              <div
                className=" mt-5 cursor divintern"
                onClick={handleAnyoneShow}
              >
                <span className="border p-2 px-4 my-3">
                  <b>Apply </b>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-5 ">
        <div className="my-2">
          <FooterFirst />
        </div>
      </div>
      <GoToTopButton />

      {/* MOdal Intern */}

      <Modal show={showIntern} onHide={handleInternClose}>
        <Modal.Header closeButton>
          <Modal.Title> Intern with us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Full Name"
              name="name"
              autoComplete="off"
              value={internForm.name}
              onChange={handleChangeIntern}
            />
            <span className="text-danger">{internFormErrors.name}</span>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="text"
              placeholder="name@example.com"
              name="email"
              autoComplete="off"
              value={internForm.email}
              onChange={handleChangeIntern}
            />
            <span className="text-danger">{internFormErrors.email}</span>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Mobile Number</Form.Label>
            <Form.Control
              type="number"
              placeholder="mobile number"
              name="mobile_no"
              autoComplete="off"
              value={internForm.mobile_no}
              onChange={handleChangeIntern}
              onWheelCapture={(e) => e.target.blur()}
              id="numbers-input"
              maxLength={10}
              onInput={(e) => {
                if(e.target.value.length > e.target.maxLength){
                  e.target.value = e.target.value.slice(0,e.target.maxLength)
                }
              }}
            />
            <span className="text-danger">{internFormErrors.mobile_no}</span>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              placeholder="description"
              name="description"
              autoComplete="off"
              value={internForm.description}
              onChange={handleChangeIntern}
            />
            <span className="text-danger">{internFormErrors.email}</span>
          </Form.Group>
          <Form.Group>
            <Form.Label>Upload Resume</Form.Label>
            <Form.Control
              type="file"
              placeholder="Upload Resume"
              className="w-50"
              name="image"
              value={internForm.image}
              onChange={handleChangeIntern}
              autoComplete="off"
            />
            <span className="text-danger">{internFormErrors.image}</span>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleInternClear}>
            Clear
          </Button>
          {loader == false && (
            <Button variant="primary" onClick={handleInternSubmit}>
              Submit
            </Button>
          )}
          {loader == true && <Spinner animation="border" variant="Primary" />}
        </Modal.Footer>
      </Modal>

      {/* Modal Anyone */}
      <Modal show={showAnyone} onHide={handleAnyoneClose}>
        <Modal.Header closeButton>
          <Modal.Title>Anyone can Apply </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Full Name"
              name="name"
              autoComplete="off"
              value={anyoneForm.name}
              onChange={handleChangeAnyone}
            />
            <span className="text-danger">{anyoneFormErrors.name}</span>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="text"
              placeholder="name@example.com"
              name="email"
              autoComplete="off"
              value={anyoneForm.email}
              onChange={handleChangeAnyone}
            />
            <span className="text-danger">{anyoneFormErrors.email}</span>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Mobile Number</Form.Label>
            <Form.Control
              type="number"
              placeholder="mobile number"
              name="mobile_no"
              autoComplete="off"
              value={anyoneForm.mobile_no}
              onChange={handleChangeAnyone}
              onWheelCapture={(e) => e.target.blur()}
              id="numbers-input"
              maxLength={10}
              onInput={(e) => {
                if(e.target.value.length > e.target.maxLength){
                  e.target.value = e.target.value.slice(0,e.target.maxLength)
                }
              }}
            />
            <span className="text-danger">{anyoneFormErrors.mobile_no}</span>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              placeholder="description"
              name="description"
              autoComplete="off"
              value={anyoneForm.description}
              onChange={handleChangeAnyone}
            />
            <span className="text-danger">{anyoneFormErrors.description}</span>
          </Form.Group>
          <Form.Group>
            <Form.Label>Upload Resume</Form.Label>
            <Form.Control
              type="file"
              placeholder="Upload Resume"
              className="w-50"
              name="image"
              value={anyoneForm.image}
              onChange={handleChangeAnyone}
              autoComplete="off"
            />
            <span className="text-danger">{anyoneFormErrors.image}</span>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleAnyoneClear}>
            clear
          </Button>
          {loader == false && (
            <Button variant="primary" onClick={handleAnyoneSubmit}>
              Submit
            </Button>
          )}
          {loader == true && <Spinner animation="border" variant="Primary" />}
        </Modal.Footer>
      </Modal>

      {/* <Modal show={showjoin} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title> Intern with us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Full Name"
              onChange={handleOnChange}
              name="fullName"
              value={formFields.fullName}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="text"
              placeholder="name@example.com"
              onChange={handleOnChange}
              name="email"
              value={formFields.email}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Message</Form.Label>
            <Form.Control
              type="text"
              placeholder="description"
              onChange={handleOnChange}
              name="description"
              value={formFields.description}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Upload Resume</Form.Label>
            <Form.Control
              type="file"
              onChange={handleOnChange}
              name="number"
              value={formFields.file}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handelSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
}

export default CareerPage;

import React from "react";
import { useState } from "react";
import { ToastContainer, toast, Zoom } from "react-toastify";
import ApiService from "../../service/ApiService";

function FooterSecond() {
  const [formFields, setFormFields] = useState({
    email: "",
  });

  const [formError, setFormError] = useState({
    fullName: "",
    email: "",
    number: "",
    message: "",
  });

  const handleValidation = () => {
    // const regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    const regText = /[A-Za-z]/;
    const rangEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const newErros = {};
    const { fullName, email, number, message } = formFields;

    if (!email) {
      newErros.email = "Please Enter Email";
    } else if (email && !rangEmail.test(email)) {
      newErros.email = "Please Enter Corect Email";
    }

    return newErros;
  };

  const handleOnChange = (e) => {
    const p = { ...formFields };
    p[e.target.name] = e.target.value;
    setFormFields(p);

    setFormError({
      ...formError,
      [e.target.name]: null,
    });
  };

  const handelSubmit = async (e) => {
    const handleValidationObject = handleValidation();
    if (Object.keys(handleValidationObject).length > 0) {
      setFormError(handleValidationObject);
    } else {
      try {
        const payload = {
          email: formFields.email,
          category: "subscribe",
        };
        const addContact = await ApiService.postData("/create_user", payload);

        if (!addContact.error) {
          const arr = { ...formFields };
          arr.email = "";
          setFormFields(arr);
          toast.success("Success");
        } else {
          console.log("Not Working");
          toast.error("API Not Working");
        }
      } catch (err) {
        console.error(err.message);
        toast.error(err.message);
      }
    }
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        transition={Zoom}
        delay={1000}
        limit={1}
      />
      <div>
        <div className="footer-2">
          <div className="row">
            <div className="col-md-12 ">
              <h1 className="text-white text-center  footer-second-text">
                {/* Get the first Eugia updates! */}
                
              </h1>
              <h5 className="text-white text-center fs-1 my-4 font2">Be the first to know!</h5>

            </div>
          </div>
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <p className="text-white text-center mx-5 mb-5 font1">
                {/* Sign up below and get our updates delivered straight to your
                inbox. */}
                Get Eugia updates delivered straight to your inbox.
              </p>
            </div>
            <div className="col-md-4"></div>
          </div>
          <div class="row inputfielddiv">
            <div className="col-md-3"></div>
            <div className="col-md-3 ">
              <div class="form-group mx-sm-3 ">
                <input
                  type="email"
                  class="form-control"
                  placeholder="Email Address"
                  name="email"
                  value={formFields.email}
                  onChange={handleOnChange}
                  autoComplete="off"
                />
                <span className="text-danger">{formError.email}</span>
              </div>
            </div>
            <div className="col-md-3 Subscribe">
              <button
                type="submit"
                class="btn btn-danger mb-2 w-50"
                onClick={handelSubmit}
              >
                Subscribe
              </button>
            </div>

            <div className="col-md-3"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FooterSecond;

import React, { useEffect, useState } from "react";
import FooterFirst from "../components/footer/FooterFirst";
import NavbarFirst from "../components/navs/Navbar";
import heroimage from "../assets/images/career-hero.png";
import { Button, Form, Image, Modal, Spinner } from "react-bootstrap";
import image1 from "./../assets/images/icon-1.png";
import image2 from "./../assets/images/icon-2.png";
import { Link, useLocation } from "react-router-dom";
import arrow from "./../assets/images/arrow.png";
import location from "./../assets/images/location.png";
import Aos from "aos";
import "aos/dist/aos.css";
import GoToTopButton from "../components/footer/GoToTopButton";
import { ToastContainer, toast, Zoom } from "react-toastify";
import ApiService from "../service/ApiService";
import axios from "axios";
import baseURL from "../service/Url";

function AssistantManagerPage() {
  useEffect(() => {
    Aos.init({});
  }, [1000]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showAnyone, setShowAnyone] = useState(false);
  const [loader, setLoader] = useState(false);

  /** State Management **/
  const [applyForm, setApplyForm] = useState({
    email: "",
    textArea: "",
  });
  const [applyFormErrors, setApplyFormErrors] = useState({
    email: "",
    textArea: "",
  });
  const [data, setData] = useState();
  const [title, setTitle] = useState();
  const [qualification, setQualification] = useState();
  const [experience, setExperience] = useState();
  const [jobLocation, setJobLocation] = useState();
  const [imgBlog, setImgBlog] = useState();
  const [description, setDescription] = useState();
  const { state } = useLocation();

  console.log(data, "data");

  useEffect(() => {
    setData(state);
    setTitle(state?.ele?.title);
    setImgBlog(state?.ele?.image);
    setDescription(state?.ele?.description);
    setQualification(state?.ele?.qualification);
    setExperience(state?.ele?.exp);
    setJobLocation(state?.ele?.job_location);
  });

  // const [formFields, setFormFields] = useState({
  //   fullName: "",
  //   email: "",
  //   file: "",
  //   description: "",
  // });

  // const handleOnChange = (e) => {
  //   const p = { ...formFields };
  //   p[e.target.name] = e.target.value;
  //   setFormFields(p);
  // };

  // const handelSubmit = async (e) => {
  //   try {
  //     const payload = {
  //       name: formFields.fullName,
  //       email: formFields.email,
  //       description: formFields.description,
  //       file: formFields.file,
  //       category: "contactus",
  //     };

  //     const addContact = await ApiService.postData("/create_user", payload);

  //     if (!addContact.err) {
  //       const arr = { ...formFields };
  //       arr.fullName = "";
  //       arr.email = "";
  //       arr.description = "";
  //       arr.file = "";
  //       setFormFields(arr);
  //       toast.success("Success");
  //     } else {
  //       toast.error(addContact.err, "Please Fill Up All Fields");
  //     }
  //   } catch (err) {
  //     toast.error(err.message, "Please Fill Up All Fields");
  //   }
  // };

  /** hnadle Apply Any One **/
  const handleAnyoneShow = () => {
    setShowAnyone(true);
  };

  const handleAnyoneClose = () => {
    setShowAnyone(false);
  };

  const handleAnyoneClear = () => {
    setAnyoneForm({
      ...anyoneForm,
      name: "",
      email: "",
      mobile_no: "",
      description: "",
      image: "",
    });
  };

  const [anyoneForm, setAnyoneForm] = useState({
    name: "",
    email: "",
    mobile_no: "",
    description: "",
    image: "",
  });

  const [anyoneFormErrors, setAnyoneFormErrors] = useState({
    name: "",
    email: "",
    mobile_no: "",
    description: "",
    image: "",
  });

  const [file1, setFile1] = useState({
    image: "",
  });
  const [fileErrors1, setFileErrors1] = useState({
    image: "",
  });

  const handleChangeAnyone = (event) => {
    if (event.target.name == "image") {
      setFile1({
        ...file1,
        [event.target.name]: event?.target?.files[0],
      });
      setFileErrors1({
        ...file1,
        image: "",
      });
    } else {
      setAnyoneForm({
        ...anyoneForm,
        [event.target.name]: event.target.value,
      });
    }
    setAnyoneForm({
      ...anyoneForm,
      [event.target.name]: event.target.value,
    });
    setAnyoneFormErrors({
      ...anyoneFormErrors,
      [event.target.name]: null,
    });
  };

  const handleAnyoneValidation = () => {
    const { name, email, mobile_no, description, image } = anyoneForm;
    const newErrors = {};
    const regText = /[A-Za-z]/;
    const regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (!name) {
      newErrors.name = "please enter name";
    } else if (name && !regText.test(name)) {
      newErrors.name = "name should be text";
    } else if (name && name.length > 50) {
      newErrors.name = "name should be below 50 charecters";
    }

    if (!email) {
      newErrors.email = "please enter email";
    } else if (email && !regEmail.test(email)) {
      newErrors.email = "please enter proper email";
    }

    if (!mobile_no) {
      newErrors.mobile_no = "please enter mobile number";
    } else if (mobile_no && mobile_no.length != 10) {
      newErrors.mobile_no = "Mobile Number should be 10 digits";
    }

    if (!description) {
      newErrors.description = "please enter Message";
    }

    if (!image) {
      newErrors.image = "please select file";
    }
    return newErrors;
  };

  const handleAnyoneSubmit = () => {
    const handleAnyoneValidationObject = handleAnyoneValidation();
    if (Object.keys(handleAnyoneValidationObject).length > 0) {
      setAnyoneFormErrors(handleAnyoneValidationObject);
    } else {
      setLoader(true);
      let formData = new FormData();
      formData.append("job_id", data?.ele?.id);
      formData.append("name", anyoneForm.name);
      formData.append("email", anyoneForm.email);
      formData.append("contact_no", anyoneForm.mobile_no);
      formData.append("description", anyoneForm.description);
      formData.append("image", file1.image);
      formData.append("category", "applyjobs");

      axios({
        url: `${baseURL}/applyjobs`,
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.status == 200) {
            toast.success("Successfully Created");
            setLoader(false);
            setAnyoneForm({
              ...anyoneForm,
              name: "",
              email: "",
              mobile_no: "",
              description: "",
              image: "",
            });
            setFile1({
              ...file1,
              image: "",
            });
            setAnyoneFormErrors({
              ...anyoneFormErrors,
              name: "",
              email: "",
              mobile_no: "",
              description: "",
              image: "",
            });
          }
        })
        .catch((err) => {
          if (err.response.status == 401) {
            toast.error("Token is Expaired");
            setLoader(false);
            setAnyoneForm({
              ...anyoneForm,
              name: "",
              email: "",
              mobile_no: "",
              description: "",
              image: "",
            });
            setAnyoneFormErrors({
              ...anyoneFormErrors,
              name: "",
              email: "",
              mobile_no: "",
              description: "",
              image: "",
            });
            setFile1({
              ...file1,
              image: "",
            });
          }
        });
    }
  };

  return (
    <>
      <NavbarFirst />

      <div className="herodiv">
        <img
          src={heroimage}
          alt="hero Img"
          width="100%"
          className="img-fluid"
        />
        <div class="aboutus-div">
          <div className="conatiner">
            <h2 className="hero-text fs-1 font2"> Career Details</h2>
          </div>
        </div>
      </div>
      <div>
        <div className="container mt-4">
          <div className="row">
            <div className="col-md-12 ">
              <span className=" text-light fs-5"> Home | Careers |</span>
              <span className="text-primary fs-5"> Career Details </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container bg-light my-4"
        data-aos="fade-up"
        data-aos-duration="3000"
      >
        <div className="row mx-3">
          <div className="col-md-8 my-5">
            <h3 className="text-primary fs-2 font2">{title}</h3>

            <div>
              <p dangerouslySetInnerHTML={{ __html: description }}></p>
            </div>
          </div>
          <div className="col-md-4 my-5">
            <button
              className="bg-secondary text-white border-0 w-100 p-2 fs-4 "
              onClick={handleAnyoneShow}
            >
              Apply Now
            </button>
            <div className="bg-white my-5 p-4">
              <div>
                <p>
                  {" "}
                  <img src={image1} className="mx-2 img-fluid" />
                  {experience}
                </p>
              </div>
              <div>
                <p>
                  {" "}
                  <img src={image2} className="mx-2 img-fluid" />
                  {qualification}
                </p>
              </div>
              <div>
                <p>
                  {" "}
                  <img src={location} className="mx-2 img-fluid" />
                  {jobLocation}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterFirst />
      <GoToTopButton />

      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title> Apply Now For Job</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Full Name"
              onChange={handleOnChange}
              name="fullName"
              value={formFields.fullName}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="text"
              placeholder="name@example.com"
              onChange={handleOnChange}
              name="email"
              value={formFields.email}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Message</Form.Label>
            <Form.Control
              type="text"
              placeholder="description"
              onChange={handleOnChange}
              name="description"
              value={formFields.description}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Upload Resume</Form.Label>
            <Form.Control
              type="file"
              onChange={handleOnChange}
              name="number"
              value={formFields.file}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Submit</Button>
        </Modal.Footer>
      </Modal> */}
      {/* Modal Anyone */}
      <Modal show={showAnyone} onHide={handleAnyoneClose}>
        <Modal.Header closeButton>
          <Modal.Title> Apply Now For Job </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Full Name"
              name="name"
              autoComplete="off"
              value={anyoneForm.name}
              onChange={handleChangeAnyone}
            />
            <span className="text-danger">{anyoneFormErrors.name}</span>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="text"
              placeholder="name@example.com"
              name="email"
              autoComplete="off"
              value={anyoneForm.email}
              onChange={handleChangeAnyone}
            />
            <span className="text-danger">{anyoneFormErrors.email}</span>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Mobile Number</Form.Label>
            <Form.Control
              type="number"
              placeholder="mobile number"
              name="mobile_no"
              autoComplete="off"
              value={anyoneForm.mobile_no}
              onChange={handleChangeAnyone}
              onWheelCapture={(e) => e.target.blur()}
              id="numbers-input"
              maxLength={10}
              onInput={(e) => {
                if(e.target.value.length > e.target.maxLength){
                  e.target.value = e.target.value.slice(0,e.target.maxLength)
                }
              }}
            />
            <span className="text-danger">{anyoneFormErrors.mobile_no}</span>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Message</Form.Label>
            <Form.Control
              type="text"
              placeholder="message"
              name="description"
              autoComplete="off"
              value={anyoneForm.description}
              onChange={handleChangeAnyone}
            />
            <span className="text-danger">{anyoneFormErrors.description}</span>
          </Form.Group>
          <Form.Group>
            <Form.Label>Upload Resume</Form.Label>
            <Form.Control
              type="file"
              placeholder="Upload Resume"
              className="w-50"
              name="image"
              value={anyoneForm.image}
              onChange={handleChangeAnyone}
              autoComplete="off"
            />
            <span className="text-danger">{anyoneFormErrors.image}</span>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleAnyoneClear}>
            clear
          </Button>
          {loader == false && (
            <Button variant="primary" onClick={handleAnyoneSubmit}>
              Submit
            </Button>
          )}
          {loader == true && <Spinner animation="border" variant="Primary" />}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AssistantManagerPage;

import React, { useEffect, useState } from "react";
import { Card, Container, Image, Navbar } from "react-bootstrap";
import logo from "./../assets/images/logo.png";
import heroimage from "./../assets/images/hero-news.png";
import newsimg from "./../assets/images/news-img.png";
import learnimg1 from "./../assets/images/learn-img-1.png";
import event from "./../assets/images/event.png";
import arrow from "./../assets/images/arrow.png";
import NavbarFirst from "../components/navs/Navbar";
import FooterFirst from "../components/footer/FooterFirst";
import NewsCard from "../components/news/NewsCard";
import NewsEvent from "../components/news/NewsEvent";
import NewsHero from "../components/news/NewsHero";
import Aos from "aos";
import "aos/dist/aos.css";
import GoToTopButton from "../components/footer/GoToTopButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ApiService from "../service/ApiService";
import { ToastContainer, toast, Zoom } from "react-toastify";
import { Helmet } from "react-helmet";

function NewsPage() {
  /** Particulat postion **/
  const location = useLocation();
  window.scrollTo(location?.state?.one, location?.state?.two);

  useEffect(() => {
    Aos.init({});
  }, [1000]);

  const [data, dataSet] = useState([]);
  useEffect(() => {
    async function fetchMyAPI() {
      try {
        let response = await ApiService.getData("/category/news");

        if (!response.error) {
          dataSet(response);
        } else {
          console.log(" Blog API Not Working");
          toast.error("Blog API Not Working");
        }
      } catch (err) {
        console.error(err.message);
        toast.error(err.message);
      }
    }

    fetchMyAPI();
  }, []);

  const navigate = useNavigate();

  const handleSendData = (ele) => {
    navigate("/news-single", {
      state: {
        ele,
      },
    });
  };
  return (
    <>
      <Helmet>
        <title>Eugia Pharma - News</title>
        <meta name="description" content="Get the latest updates on Eugia" />
      </Helmet>

      <NavbarFirst />

      <div className="herodiv">
        <img
          src={heroimage}
          alt="hero Img"
          width="100%"
          className="img-fluid"
        />
        <div class="aboutus-div">
          <div className="conatiner">
            <h2 className="hero-text fs-1 font2"> News and Media</h2>
          </div>
        </div>
      </div>
      <div>
        <div className="container mt-4">
          <div className="row py-3 my-3">
            <div className="col-md-12 ">
              <span className=" text-light fs-5 font1"> Home | </span>
              <span className="text-primary fs-5 font1"> Press Release</span>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        {/* <div className="row py-3 my-3 ">
          <div className="col-md-6 px-4">
            <Image src={newsimg} className="new-img-1 img-fluid" />
          </div>
          <div className="col-md-6 my-3 px-4">
            <div className="border-investore"></div>
            <h3 className="fs-2 carrertext font-weight-bold py-3 font2 text-primary">
              Here you will find our latest & archived press releases, stories
              and our corporate brand guidelines.{" "}
            </h3>
            <p className="py-2 font1 ">
              Browse Eugia Pharma Specialties Ltd press releases.
            </p>
          </div>
        </div> */}
        {/* <div className="row my-3 py-3">
          <h1 className="text-uppercase text-center text-primary  font2 display-6">
            News
          </h1>
        </div>{" "} */}
        <div className="row">
          <div className="row">
            {[...data].reverse().map((ele) => (
              <>
                {" "}
                <div className="col-md-4 my-2">
                  <div class="card">
                    <Image
                      className="card-img-top1 img-fluid"
                      src={ele?.image}
                      alt="Card image cap"
                    />
                    <div class="card-body bg-light newscard">
                      <p class=" text-primary font2 fs-2"> {ele?.title}</p>

                      <p
                        dangerouslySetInnerHTML={{
                          __html: ele?.description.slice(0, 53),
                        }}
                        className="font1"
                      ></p>

                      <div className="">
                        <p
                          className="learn-more text-secondary mt-3"
                          onClick={() => handleSendData(ele)}
                        >
                          <Link> EXPLORE NEWS</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
      {/* <NewsEvent data-aos="fade-up" data-aos-duration="3000" /> */}
      <div className="my-5 ">
        <div className="my-2">
          <FooterFirst />
        </div>
      </div>
      <GoToTopButton />
    </>
  );
}

export default NewsPage;

import React from "react";
import { useLocation } from "react-router-dom";
import FooterFirst from "../components/footer/FooterFirst";
import FooterSecond from "../components/footer/FooterSecond";
import GoToTopButton from "../components/footer/GoToTopButton";
import NavbarFirst from "../components/navs/Navbar";
import PageHistory from "../components/navs/PageHistory";
import treatmentshero from "./../assets/images/treatments-hero.png";
import img1 from "./../assets/images/tretment-img-1.png";

function PrivacyPolicy() {
  const location = useLocation();
  window.scrollTo(location?.state?.one, location?.state?.two);

  return (
    <div>
      <NavbarFirst />

      <div className="herodiv">
        <img src={treatmentshero} alt="hero Img" width="100%" />
        <div class="aboutus-div">
          <div className="conatiner">
            <h2 className="hero-text fs-1 font2"> Privacy & Policy</h2>
          </div>
        </div>
      </div>
      <PageHistory Text="Privacy Policy" />
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-12  text-primary">
            <h5>
              <b> Privacy Policy</b>
            </h5>
          </div>
        </div>

        <div className="row ">
          <div className="col-md-12">
            <p>
              Eugia Pharma Specialties Ltd is committed to protecting the
              privacy and security of your information. The protection of your
              privacy in the processing of your personal data is an important
              concern to which we pay special attention during our business
              processes. We process personal data collected during visits to our
              websites according to the legal provisions valid for the countries
              in which the websites are maintained. This privacy notice
              describes the information about you that collects through this
              site, how that information is used, maintained, shared, protected
              and how you can update it. It also applies to all personal data
              received by from the European Economic Area (“EEA”) in any format,
              including electronic or paper. It is effective on the date posted
              below and applies to our use of your information after that date.
            </p>

            <p>
              <b>1. Personal data we collect about you</b>
            </p>
            <p>
              We collect personal data from you, for example, if you register
              for an event, request information, product or use our services, or
              request customer support. We may ask you to provide information
              such as your name, address, zip code, phone number, email address,
              job title, IP Address, and information about your device. Not all
              of the personal data holds about you, will always come directly
              from you. It may, for example, come from your employer, other
              organizations to which you belong, or a professional service
              provider such as your tax or accounting professional or attorney,
              if they use our Services. However, collects Personal data about
              you when you interact with this site and/or utilize services
              offered on this site. For example: If you apply for a job or other
              staffing opportunity through this site, you will be asked to
              submit your resume and as well as other contact information such
              as your email address, phone number and mailing address. We will
              use this information to consider you for the job opening that you
              specify. We may also use this information to contact you regarding
              other staffing opportunities, both opportunities advertised on
              this site. We may use a third party service provider to assist us
              with some features of our site. Our service provider will receive
              your information on our behalf and will not be permitted to use it
              for any other purpose. We may also ask you for personal data in
              connection with other interactions you have with this Site,
              including when you answer a survey and when you report a problem
              with this site or services offered on this site. We may also
              collect personal information from third parties such as our
              partners, service providers, and publicly available websites, to
              offer services we think may be of interest and to help us maintain
              data accuracy, provide, and enhance the services. We may also
              collect special categories of personal data such as information
              concerning health when you report a problem with this site or
              services offered by us.
            </p>
          </div>
        </div>
      </div>

      <FooterFirst />
      <GoToTopButton />
    </div>
  );
}

export default PrivacyPolicy;

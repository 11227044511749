import React, { useEffect, useState } from "react";
import FooterFirst from "../components/footer/FooterFirst";
import NavbarFirst from "../components/navs/Navbar";
import heroproduct from "../assets/images/hero-keytheropy.png";
import card1 from "../assets/images/services-img-1.png";
import card2 from "../assets/images/services-img-2.png";
import card3 from "../assets/images/services-img-3.png";
import PageHistory from "../components/navs/PageHistory";
import { Button, Card, Offcanvas, Spinner } from "react-bootstrap";
import GoToTopButton from "../components/footer/GoToTopButton";
import ApiService from "../service/ApiService";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

function ServicePage() {
  const [listData, setListData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loaderList, setLoaderList] = useState(false);
  const [firstData, setFirstData] = useState([]);
  const [show, setShow] = useState(true);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(location?.state?.one, location?.state?.two);
  }, [location?.state?.two]);

  // useEffect(() => {}, [firstData]);

  useEffect(() => {
    setLoaderList(true);
    async function fetchMyAPI() {
      try {
        let response = await ApiService.getData("/category/treatment_category");
        let response2 = await ApiService.getData(`/category/treatment`);

        if (!response.error) {
          const sortedList = response.sort((a, b) =>
            a.title.localeCompare(b.title)
          );
          setListData(sortedList);

          setLoaderList(false);
        } else {
          console.log(" Blog API Not Working");
          setLoaderList(false);
        }

        if (!response2.error) {
          const sortedList = response2.sort((a, b) =>
            a.title.localeCompare(b.title)
          );

          setFirstData(sortedList);

          setLoaderList(false);
        } else {
          console.log(" Blog API Not Working");
          setLoaderList(false);
        }
      } catch (err) {
        console.error(err.message);
      }
    }

    fetchMyAPI();
  }, []);

  const [data, dataSet] = useState([]);
  const [titleData, setTitleData] = useState();

  // console.log(titleData.title, 'titleData');

  const handleClick = (ele) => {
    setShow11(false);
    setTitleData(ele);
    setShow(false);
    setLoader(true);
    async function fetchMyAPI() {
      try {
        let response = await ApiService.getData(
          `/category/treatment/${ele.id}`
        );

        if (!response.error) {
          const sortedList = response.sort((a, b) =>
            a.title.localeCompare(b.title)
          );

          dataSet(sortedList);
          setLoader(false);
        } else {
          console.log(" Blog API Not Working");
          setLoader(false);
        }
      } catch (err) {
        console.error(err.message);
      }
    }
    fetchMyAPI();
  };

  // useEffect(() => {
  //   async function fetchMyAPI() {
  //     try {
  //       let response = await ApiService.getData("/category/treatment");
  //       if (!response.error) {
  //         dataSet(response);
  //       } else {
  //         console.log(" Blog API Not Working");
  //       }
  //     } catch (err) {
  //       console.error(err.message);
  //     }
  //   }
  //   fetchMyAPI();
  // }, []);

  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <p className="text">
        {isReadMore ? text.slice(0, 150) : text}
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? "...read more" : " show less"}
        </span>
      </p>
    );
  };

  const [show11, setShow11] = useState(false);

  const handleClose = () => setShow11(false);
  const handleShow = () => setShow11(true);
  // console.log("Sort Array of Objects Alphabetically");
  // const Std_arr = [{ name: "John" }, { name: "Adam" }, { name: "Peter" }];
  // const sortedList = Std_arr.sort((a, b) => a.name.localeCompare(b.name));
  // console.log(sortedList);

  console.log(data, "data ===>");
  console.log(firstData, "firstData ===>");
  const sortedList = firstData.sort((a, b) => a.title.localeCompare(b.title));
  console.log(sortedList, "sortedList ===>");

  return (
    <>
      <Helmet>
        <title>Eugia Pharma - Key Therapeutic</title>
        <meta
          name="description"
          content="This catalog is categorized of all the products that we provide. We offer a variety of solutions for the cardiovascular, gastrointestinal & respiratory therapies"
        />
      </Helmet>
      <div>
        <NavbarFirst />
        <div className="herodiv ">
          <img
            src={heroproduct}
            alt="hero Img"
            width="100%"
            className="img-fluid"
          />
          <div class="aboutus-div">
            <div className="conatiner">
              <h2 className="hero-text fs-1 font2"> Key Therapeutic Areas</h2>
            </div>
          </div>
        </div>

        <div className="divservice">
          <div className="row">
            <div className="col-md-4 text-white bg-primary divservice">
              <div className="">
                {listData &&
                  listData.map((ele, ind) => {
                    return (
                      <ul className="m-5" onClick={() => handleClick(ele)}>
                        <li className="my-3 pb-3 border-bottom border-white">
                          {listData && (
                            <Link
                              to=""
                              className="  text-decoration-none text-white"
                            >
                              {ele.title}
                            </Link>
                          )}
                        </li>
                      </ul>
                    );
                  })}
              </div>
              {loaderList == true && (
                <div style={{ textAlign: "center", marginTop: "100px" }}>
                  <Spinner animation="border" variant="Primary" />
                </div>
              )}
            </div>
            <div className="col-md-8">
              <div className="container mt-4">
                <div className="row">
                  <div className="col-md-12 ">
                    <span className=" text-light fs-5"> Home | </span>
                    <span className="text-primary fs-5">
                      {/* Treatments */}
                      Key Therapeutic Areas
                    </span>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="row my-3">
                  <div className="col-md-12 ">
                    <h3 className="text-primary">
                      {/* <b>ANTI-DOTE </b> */}
                      <b>{titleData?.title}</b>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="row my-3">
                  <div className="row">
                    {data.map((ele) => (
                      <>
                        <div className="col-md-6 px-5 my-3">
                          <Card className="p-2">
                            {/* <div className="card-div cursor">
                              <Card.Img
                                variant="top"
                                src={ele?.image}
                                className="cardimg card-img-top1 img-fluid"
                              />
                            </div> */}

                            <Card.Body className="keycard">
                              <Card.Title className="text-primary  m-auto fs-2 font2">
                                {ele?.title}{" "}
                              </Card.Title>
                              <Card.Text
                                className=" my-3"
                                dangerouslySetInnerHTML={{
                                  __html: ele?.description.slice(0, 300),
                                }}
                              ></Card.Text>
                            </Card.Body>
                          </Card>
                        </div>
                      </>
                    ))}

                    {show &&
                      firstData.map((ele) => (
                        <>
                          <div className="col-md-6 px-5 my-3">
                            <Card className="p-2">
                              {/* <div className="card-div cursor">
                                <Card.Img
                                  variant="top"
                                  src={ele?.image}
                                  className="cardimg card-img-top1 img-fluid"
                                />
                              </div> */}

                              <Card.Body className="keycard">
                                <Card.Title className="text-primary  m-auto fs-2 font2">
                                  {ele?.title}{" "}
                                </Card.Title>
                                <Card.Text
                                  className="my-3"
                                  dangerouslySetInnerHTML={{
                                    __html: ele?.description.slice(0, 300),
                                  }}
                                ></Card.Text>
                              </Card.Body>
                            </Card>
                          </div>
                        </>
                      ))}

                    {loader == true && (
                      <div style={{ textAlign: "center", marginTop: "50px" }}>
                        <Spinner animation="border" variant="Primary" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mobilediv">
          <Offcanvas
            show={show11}
            onHide={handleClose}
            placement="end"
            className="text-white bg-off "
          >
            <Offcanvas.Header>
              <Offcanvas.Title>Products Category</Offcanvas.Title>{" "}
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="">
                {listData &&
                  listData.map((ele, ind) => {
                    return (
                      <ul className="" onClick={() => handleClick(ele)}>
                        <li className="my-3 pb-3 border-bottom border-white">
                          {listData && (
                            <Link
                              to=""
                              className="  text-decoration-none text-white"
                            >
                              {ele.title}
                            </Link>
                          )}
                        </li>
                      </ul>
                    );
                  })}
              </div>
              {loaderList == true && (
                <div style={{ textAlign: "center", marginTop: "100px" }}>
                  <Spinner animation="border" variant="Primary" />
                </div>
              )}
            </Offcanvas.Body>
          </Offcanvas>

          <div className="container">
            <div className="row my-3">
              <div className=" Filterdiv1">
                <h3 className="text-primary">
                  {/* <b>ANTI-DOTE </b> */}
                  {titleData ? titleData?.title : <span>ANTI - DOTE</span>}
                </h3>
              </div>
              <div className="Filterdiv">
                <Button className="Category-btn" onClick={handleShow}>
                  Category
                </Button>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row my-3">
              <div className="row">
                {loaderList == true && (
                  <div style={{ textAlign: "center", marginTop: "100px" }}>
                    <Spinner animation="border" variant="Primary" />
                  </div>
                )}
                {data.map((ele) => (
                  <>
                    <div className="col-md-6 my-2 mx-2 text-center m-auto">
                      <Card className="p-2">
                        {/* <div className="card-div cursor">
                          <Card.Img
                            variant="top"
                            src={ele?.image}
                            className="cardimg card-img-top1 img-fluid"
                          />
                        </div> */}

                        <Card.Body className="keycard">
                          <Card.Title className="text-primary  m-auto fs-2 font2">
                            {ele?.title}{" "}
                          </Card.Title>
                          <Card.Text
                            className=" my-3"
                            dangerouslySetInnerHTML={{
                              __html: ele?.description.slice(0, 300),
                            }}
                          ></Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  </>
                ))}

                {show &&
                  firstData.map((ele) => (
                    <>
                      <div className="col-md-6 my-2 mx-2 text-center m-auto">
                        <Card className="p-2">
                          {/* <div className="card-div cursor">
                            <Card.Img
                              variant="top"
                              src={ele?.image}
                              className="cardimg card-img-top1 img-fluid"
                            />
                          </div> */}

                          <Card.Body className="keycard">
                            <Card.Title className="text-primary  m-auto fs-2 font2">
                              {ele?.title}{" "}
                            </Card.Title>
                            <Card.Text
                              className="my-3"
                              dangerouslySetInnerHTML={{
                                __html: ele?.description.slice(0, 300),
                              }}
                            ></Card.Text>
                          </Card.Body>
                        </Card>
                      </div>
                    </>
                  ))}

                {loader == true && (
                  <div style={{ textAlign: "center", marginTop: "50px" }}>
                    <Spinner animation="border" variant="Primary" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 pt-5 FooterSecondmobile">
          <div className="">
            <FooterFirst />
          </div>
        </div>
      </div>
    </>
  );
}

export default ServicePage;

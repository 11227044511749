import React,{useState,useEffect} from 'react'
import { Image,Spinner } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import FooterFirst from "../components/footer/FooterFirst";
import NavbarFirst from "../components/navs/Navbar";
import { ToastContainer,toast, Zoom } from "react-toastify";
import arrow_left from "../assets/images/arrow_left.png";
import ApiService from "../service/ApiService";
import upload from "../assets/images/upload.png";
import CountryDetails from '../components/CountryDetails';

function Event() {
    const [loadstate, setloadstate] = useState(false);
    const [formError, setFormError] = useState({
      fullName: '',
      email: '',
      number: '',
      message: "",
      country: '',
      image: '',
    });
    const [formFields, setFormFields] = useState({
      fullName: '',
      email: '',
      number: '',
      message: "",
      country: '',
    });
  
    const [file, setFile] = useState({ image: null });
    const [fileErrors, setFileErrors] = useState({
      image: '',
    });
  
    const handleFileChange = (event) => {
        if (event.target.name === 'image') {
            setFile({
                ...file,
                image: event?.target?.files[0],
            });
            setFileErrors({
                ...file,
                image: '',
            });
        } else {
            setFormFields({
                ...formFields,
                [event.target.name]: event.target.value,
            });
        }
        setFormError({
            ...formError,
            [event.target.name]: null,
        });
    };
  
    const handleOnChange = (e) => {
      const p = { ...formFields };
      p[e.target.name] = e.target.value;
      setFormFields(p);
  
      setFormError({
        ...formError,
        [e.target.name]: null,
      });
    };
  
    const handleValidation = () => {
      const regText = /[A-Za-z]/;
      const rangEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      const newErros = {};
      const { fullName, email, number,message,country } = formFields;
  
      if (!fullName) {
        newErros.fullName = 'Please Enter Full Name';
      } else if (fullName && !regText.test(fullName)) {
        newErros.fullName = 'Full Name should be text';
      } else if (fullName && fullName.length > 50) {
        newErros.fullName = 'FullName should be below 50 characters';
      }
  
      if (!email) {
        newErros.email = 'Please Enter Email';
      } else if (email && !rangEmail.test(email)) {
        newErros.email = 'Please Enter Correct Email';
      }
  
      if (!number) {
        newErros.number = 'Please enter mobile number';
      } else if (number && number.length !== 10) {
        newErros.number = 'Mobile Number should be 10 digits';
      }
      if (!message) {
        newErros.message = "Please enter Description";
      }
      if (!country) {
        newErros.country = "Please enter Country";
      }
  
      if (!file.image) {
        fileErrors.image = 'Please upload a document';
      }
  
      return newErros;
    };
  
    const handelSubmit = async (e) => {
        const handleValidationObject = handleValidation();
        if (Object.keys(handleValidationObject).length > 0) {
          setFormError(handleValidationObject);
        } else {
          try {
            setloadstate(true);
      
            const formData = new FormData();
            formData.append('name', formFields.fullName);
            formData.append('email', formFields.email);
            formData.append('mobile_no', formFields.number);
            formData.append('description', formFields.message);
            formData.append('country', formFields.country);
            formData.append('category', 'adverseevent');
      
            // Check if file.image is not null before appending it to FormData
            if (file.image) {
              formData.append('image', file.image);
            }
      
            const addContact = await ApiService.postData('/create_user', formData);
            setloadstate(false);
      
            if (!addContact.err) {
              const arr = { ...formFields };
              arr.fullName = '';
              arr.email = '';
              arr.number = '';
              arr.message = '';
              arr.country = '';
              setFormFields(arr);
              setFile({ image: null });
              toast.success('Success');
            } else {
              toast.error(addContact.err, 'Please Fill Up All Fields');
            }
          } catch (err) {
            toast.error(err.message, 'Please Fill Up All Fields');
          }
        }
      };
      useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

      const workDetails = [
        [ "Australia", "02-84889399","" ],
        [ "Belgium", "+32 24753553", "+32 2 475 35 55" ],
        [ "Brazil", "+ 62 40153400", "+62 4015 3401"],
        [ "Canada", "+905 8568063", "+905 856 8094" ],
        [ "Colombia", "+57 3102424285","" ],       
        [ "France", "+334 72716397", "+334 72726070" ],
        [ "Germany", "+49 895589090", "+49 89558909240" ],
        [ "Gulf Cooperation Council", "+97145785842","" ],
        [ "India", "+91 4023046918", "91 4023044875" ],
        [ "Ireland", "+44 (0)2088458811","+ 44 2088458795" ],
        [ "Italy", "+39 02 96392601", "+39 0296193260" ],
        [ "Kazakhstan", "+77770642702, +7 4995041519, +77754392061", "" ],
        [ "Kyrgyz Republic", "+996 999015045, +7 4995041519", "" ],
        [ "Malta", "+356 22294000","" ],
        [ "Mexico", "+52 5527816588","" ],
        [ "Netherlands", "+31 (0)35 542 9933","" ],
        [ "Poland", "+48 223112000","" ],
        [ "Portugal", "+351 219849300", "+351 214967129" ],
        [ "Romania", "(00)40748195240","" ],
        [ "Republic of Belarus", "+7 4995041519, +7903 7992186", "" ],
        [ "South Africa", "+27 11 867 9100", "+27 118679111" ],
        [ "Spain", "+34 91 630 86 45", "+34 916302664" ],
        [ "UK", "+44 (0)2088458811", "+ 44 2088458795" ],
        [ "United States of America", "1-866-850-2876", "+1 7329174234" ],
      ];
      
  return (
    <div>
      <Helmet>
        <title>Eugia Pharma - Event</title>
        <meta name="description" content="Eugia- Investor" />
      </Helmet>

      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar
        transition={Zoom}
        delay={500}
        limit={1}
      />
      <NavbarFirst />

      <div className="container general-head">
        <div className="general-head2">
          <Link to="/contact-us">
            <Image
              src={arrow_left}
              style={{ width: "40px", height: "40px", cursor: "pointer" }}
            />
          </Link>
          <span className="text-primary  font2 display-5">
            Adverse Event Reporting
          </span>
        </div>
        <div className="contact-bg py-3">
          <div className="row">
            <div className="col-md-6 event-list">
              <input
                type="text"
                className="form-control contact-input mt-5"
                placeholder="Full Name"
                onChange={handleOnChange}
                name="fullName"
                value={formFields.fullName}
              />
              <span className="text-danger">{formError.fullName}</span>
              <input
                type="text"
                className="form-control contact-input  mt-5"
                placeholder="Email address"
                onChange={handleOnChange}
                name="email"
                value={formFields.email}
              />
              <span className="text-danger">{formError.email}</span>
              <input
                type="number"
                className="form-control contact-input  mt-5"
                placeholder="Phone Number"
                onChange={handleOnChange}
                name="number"
                value={formFields.number}
                id="numbers-input"
                maxLength={10}
                onInput={(e) => {
                  if (e.target.value.length > e.target.maxLength) {
                    e.target.value = e.target.value.slice(
                      0,
                      e.target.maxLength
                    );
                  }
                }}
              />
              <span className="text-danger">{formError.number}</span>
            </div>
            <div className="col-md-6 event-list">
              <div className="mt-5">
              <input
                type="text"
                className="form-control contact-input  mt-5"
                placeholder="Country"
                onChange={handleOnChange}
                name="country"
                value={formFields.country}
              />
              <span className="text-danger">{formError.country}</span>
                <input
                  type="text"
                  className="form-control contact-input mt-5"
                  placeholder="Type your description here"
                  onChange={handleOnChange}
                  name="message"
                  value={formFields.message}
                />
                <span className="text-danger">{formError.message}</span>
              </div>
              <div className="event-upload-div mt-4">
                <p className="text-white">Upload Document</p>
                <div className="form-group py-2 position-relative">
                  <input
                    id="vendorimage"
                    type="file"
                    style={{ display: "none" }}
                    name="image"
                    onChange={handleFileChange}
                  />
                  <label htmlFor="vendorimage" className="upload2">
                    {" "}
                    {/* Use label with htmlFor */}
                    <div>
                      <Image src={upload} alt="upload" />
                    </div>
                  </label>
                </div>
                {file.image && (
                  <span className="text-white">{file.image.name}</span>
                )}
                <span className="text-danger">{fileErrors.image}</span>
              </div>
              
            </div>
          </div>
          <div className="text-center">
            <button
              type="button"
              className="btn bg-secondary text-white py-2 rounded-0 mt-5 text-center"
              onClick={handelSubmit}
            >
              Submit
              {loadstate ? (
                <Spinner animation="border" variant="Primary" size="sm" />
              ) : (
                ""
              )}
            </button>
          </div>
          <div className='col-md-8 mt-4 event-list'>
            <p className='complaint-name'>Call us directly</p>
            <CountryDetails countries={workDetails} />
          </div>
         
        </div>
      </div>
      <div className="my-5">
        <div className="my-2">
          <FooterFirst />
        </div>
      </div>
    </div>
  );
}

export default Event
import React, { useEffect, useState } from "react";
import FooterFirst from "../components/footer/FooterFirst";
import GoToTopButton from "../components/footer/GoToTopButton";
import NavbarFirst from "../components/navs/Navbar";
import PageHistory from "../components/navs/PageHistory";
import img1 from "./../assets/images/csr1.png";
import img2 from "./../assets/images/csr2.png";
import img3 from "./../assets/images/csr3.png";
import abouthero from "../assets/images/about-hero.png";
import ApiService from "../service/ApiService";
import { Card } from "react-bootstrap";

function Responsibility() {
  const [data, dataSet] = useState([]);
  useEffect(() => {
    async function fetchMyAPI() {
      try {
        let response = await ApiService.getData("/category/responsibility");
        if (!response.error) {
          dataSet(response);
        } else {
          console.log(" Blog API Not Working");
        }
      } catch (err) {
        console.error(err.message);
      }
    }
    fetchMyAPI();
  }, []);
  console.log(data);
  return (
    <div>
      <NavbarFirst />
      <div className="herodiv">
        <img src={abouthero} alt="hero Img" width="100%" />
        <div class="aboutus-div">
          <div className="conatiner">
            <h2 className="hero-text fs-1 font2">Responsibility</h2>
          </div>
        </div>
      </div>
      <PageHistory Text="Responsibilities" />{" "}
      <div className="container">
        <div className="row mb-5">
          <div className="row my-3">
            <div className="row">
              {data.map((ele) => (
                <>
                  <div className="col-md-4 my-2">
                    <Card className="p-2">
                      <div className="card-div cursor">
                        <Card.Img
                          variant="top"
                          src={ele?.image}
                          className="cardimg"
                        />
                      </div>

                      <Card.Body>
                        <Card.Title className="text-primary  m-auto fs-2 font2">
                          {ele?.title}{" "}
                        </Card.Title>
                        <Card.Text
                          className=" my-3"
                          dangerouslySetInnerHTML={{
                            __html: ele?.description,
                          }}
                        ></Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
      <FooterFirst />
      <GoToTopButton />
    </div>
  );
}

export default Responsibility;

import React, { useEffect, useState } from "react";
import NavbarFirst from "../components/navs/Navbar";
import heroproduct from "../assets/images/hero-product.png";
import PageHistory from "../components/navs/PageHistory";
import image1 from "./../assets/images/product1.png";
import image2 from "./../assets/images/product2.png";
import image3 from "./../assets/images/product3.png";
import imgproduct4 from "./../assets/images/product4.png";
import imgproduct5 from "./../assets/images/product5.png";
import imgproduct6 from "./../assets/images/product6.png";
import productformimg from "./../assets/images/product-form-img.png";
import productperson from "./../assets/images/productperson.png";
import FooterFirst from "../components/footer/FooterFirst";
import { Button, Card, Form, Image } from "react-bootstrap";
import GoToTopButton from "../components/footer/GoToTopButton";
import { ToastContainer, toast, Zoom } from "react-toastify";
import ApiService from "../service/ApiService";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function ProductPage() {
  const [data, dataSet] = useState([]);
  /** Particulat postion **/
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(location?.state?.one, location?.state?.two);
  }, [location?.state?.two]);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        let response = await ApiService.getData("/category/products");
        if (!response.error) {
          dataSet(response);
        } else {
          console.log(" Blog API Not Working");
        }
      } catch (err) {
        console.error(err.message);
      }
    }
    fetchMyAPI();
  }, []);

  const [formFields, setFormFields] = useState({
    fullName: "",
    email: "",
    number: "",
  });

  const [formError, setFormError] = useState({
    fullName: "",
    email: "",
    number: "",
  });

  const handleValidation = () => {
    // const regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    const regText = /[A-Za-z]/;
    const rangEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const newErros = {};
    const { fullName, email, number, message } = formFields;
    if (!fullName) {
      newErros.fullName = "Please Enter Full Name";
    } else if (fullName && !regText.test(fullName)) {
      newErros.fullName = "Full Name should be text";
    } else if (fullName && fullName.length > 50) {
      newErros.fullName = "FullName should be below 50 charecters";
    }

    if (!email) {
      newErros.email = "Please Enter Email";
    } else if (email && !rangEmail.test(email)) {
      newErros.email = "Please Enter Corect Email";
    }

    if (!number) {
      newErros.number = "please enter mobile number";
    } else if (number && number.length != 10) {
      newErros.number = "Mobile Number should be 10 digits";
    }

    return newErros;
  };

  const handleOnChange = (e) => {
    const p = { ...formFields };
    p[e.target.name] = e.target.value;
    setFormFields(p);
    setFormError({
      ...formError,
      [e.target.name]: null,
    });
  };

  const handelSubmit = async (e) => {
    const handleValidationObject = handleValidation();
    if (Object.keys(handleValidationObject).length > 0) {
      setFormError(handleValidationObject);
    } else {
      try {
        const payload = {
          name: formFields.fullName,
          email: formFields.email,
          mobile_no: formFields.number,
          category: "enquiry",
        };
        const addContact = await ApiService.postData("/create_user", payload);

        if (!addContact.error) {
          const arr = { ...formFields };
          arr.fullName = "";
          arr.email = "";
          arr.number = "";
          setFormFields(arr);
          toast.success("Success");
          console.log(addContact);
        } else {
          console.log("Not Working");
          toast.error("API Not Working");
        }
      } catch (err) {
        console.error(err.message);
        toast.error(err.message);
      }
    }
  };

  const navigate = useNavigate();
  const handleClickKeyTheripetic = () => {
    navigate("/treatments");
  };

  return (
    <>
      <Helmet>
        <title>Eugia Pharma - Product</title>
        <meta
          name="description"
          content="Eugia produces state-of-the-art solutions for Oncology, hormonal injectables, & sterile products. Our manufacturing units promise high quality & industry standard products."
        />
      </Helmet>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        transition={Zoom}
        delay={1000}
        limit={1}
      />
      <div>
        <NavbarFirst />

        <div className="herodiv">
          <img
            src={heroproduct}
            alt="hero Img "
            width="100%"
            className="img-fluid"
          />
          <div class="aboutus-div">
            <div className="conatiner">
              <h2 className="hero-text fs-1 font2"> Products</h2>
            </div>
          </div>
        </div>

        <PageHistory Text="Products" />
        <div className="container">
          <div className="row my-5 pb-5 productaboutusdiv divproductm">
            <div className="col-md-12">
              <h2 className="medial-text font2 text-center">
                Commitment, dedication and drive to manufacture
                <b> Exceptional Medicines. </b>
              </h2>

              <p className="medical-ptag font1 text-center">
                {" "}
                With a total of 6 manufacturing units of its own, Eugia produces
                a variety of differentiated and unique products. Adhering to the
                guidelines of all regulatory bodies, Eugia’s manufacturing is
                sustainable with superior technical expertise .{" "}
              </p>
            </div>
          </div>

          <div className="row my-3 py-3 divproductm">
            <div className="col-md-6">
              <div className="productnamemobile">
                <div className="border-investore"></div>
                <h3 className="display-6  font-weight-bold text-primary font2 my-2">
                  STERILES
                </h3>
              </div>
              <Image src={image1} className="img-fluid" />
            </div>
            <div className="col-md-6 ">
              <div className="border-investore productname"></div>
              <div>
                <h3 className="productname display-6  font-weight-bold text-primary font2 my-2">
                  STERILES
                </h3>
                <p className="py-1 my-3 font1 about-text-p w-75 investoretext1">
                  Eugia is in the forefront of developing and manufacturing
                  sterile products. Our products are supplied across the globe
                  in various forms such as Vials, Dry Vials (Lyophilized or
                  Powder for injection), Depot, Ampoules, Bulk Pharmaceuticals
                  (Bags and Bottles), and Pre-filled syringes.
                </p>
              </div>
            </div>
          </div>

          <div className="row my-3 py-3 divproductm">
            <div className="col-md-6 productnamemobile">
              <div className="productnamemobile">
                <div className="border-investore"></div>
                <h3 className="display-6  font-weight-bold text-primary font2 my-2">
                  ONCOLOGY
                </h3>
              </div>
              <Image src={image2} className="img-fluid" />
            </div>
            <div className="col-md-6 ">
              <div className="border-investore productname"></div>
              <div>
                <h3 className="display-6  font-weight-bold text-primary font2 my-2 productname">
                  ONCOLOGY
                </h3>
                <p className="py-1 my-3 font1 about-text-p w-75 investoretext1">
                  Eugia has dedicated and approved units to develop and
                  manufacture high quality sterile injectables and oral solid
                  for various Oncology products to treat patients with relapsed
                  or refractory multiple myeloma, ovarian cancer, colorectal
                  cancer, breast cancer, etc.
                </p>
              </div>
            </div>
            <div className="col-md-6 imgproduct2">
              <Image src={image2} className="img-fluid " />
            </div>
          </div>

          <div className="row my-3 py-3 divproductm">
            <div className="col-md-6 ">
              <div className="productnamemobile">
                <div className="border-investore "></div>
                <h3 className="display-6  font-weight-bold text-primary font2 my-2 ">
                  HORMONALS
                </h3>
              </div>
              <Image src={image3} className="img-fluid" />
            </div>
            <div className="col-md-6 ">
              <div className="border-investore imgproduct2"></div>
              <div>
                <h3 className="display-6  font-weight-bold text-primary font2 my-2 imgproduct2">
                  HORMONALS
                </h3>
                <p className="py-1 my-3 font1 about-text-p w-75 investoretext1">
                  Eugia produces a wide range of hormonal drugs and injectables
                  at our highly efficient, state-of-art equipped manufacturing
                  units to cater to wide range of hormones related treatments
                </p>
              </div>
            </div>
          </div>
          <div className="row text-center pt-5 viewmore">
            <div className="col-md-12">
              <div className="lernhover cursor ">
                <span className="text-secondary learn-more  fs-5 ">
                  <Link to="/treatments" className="text-decoration-none ">
                    EXPLORE MORE
                  </Link>
                </span>
              </div>
            </div>
          </div>
          {/* 
          <div className="row my-3">
            <div className="row">
              {data.map((ele) => (
                <>
                  
                  <div
                    className="col-md-6  cursor"
                    onClick={handleClickKeyTheripetic}
                  >
                    <Image src={ele?.image} className="w-75  h-75" />
                  </div>
                  <div
                    className="col-md-6 px-5"

                  >
                    <div className="border-investore"></div>
                    <div>
                      <h3 className="display-6  font-weight-bold text-primary font2 my-2">
                        {ele?.title}
                      </h3>
                      <p className="py-1 my-3 font1 about-text-p">{ele?.description}</p>
                    </div>

                    <div className="">
                      <p className="learn-more text-secondary mt-3">
                        <Link to="/treatments">EXPLORE </Link>
                      </p>
                    </div>
                  </div>
                </>
              ))}
            </div>

            <div className="row text-center py-5">
              <div className="col-md-12">
                <button className="btn btn font-weight-bold">
                  <Link to="/treatments" className=" text-decoration-none text-primary fs-3 font-weight-bold">
                    Explore More
                  </Link>
                </button>
              </div>
            </div>
          </div> */}
        </div>
        <div className="container productformdiv">
          <div className="row my-5 py-5 divproductm">
            <div className="col-md-6 p-0 productimg produtformimg">
              <img
                src={productperson}
                width="100%"
                className="productimg img-fluid"
              />
            </div>
            <div className="col-md-6 p-0 productformimg productimg">
              <div className="row">
                <div className="col-md-12 text-white ">
                  <div className="w-50 m-auto ">
                    <h2 className="my-5  font2 text-center">
                      Get to know more about our products
                    </h2>
                    <div className="mb-4">
                      <input
                        style={{ width: "100%" }}
                        type="text"
                        placeholder="Full Name"
                        className="py-3 px-3"
                        name="fullName"
                        onChange={handleOnChange}
                        value={formFields.fullName}
                      />{" "}
                      <span className="text-danger">{formError.fullName}</span>
                    </div>

                    <Form.Group className="mb-4">
                      <input
                        style={{ width: "100%" }}
                        type="email"
                        placeholder="Email Id"
                        className="py-3  px-3"
                        name="email"
                        onChange={handleOnChange}
                        value={formFields.email}
                      />{" "}
                      <span className="text-danger">{formError.email}</span>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <input
                        style={{ width: "100%" }}
                        type="number"
                        id="numbers-input"
                        placeholder="Mobile Number"
                        className="py-3  px-3"
                        name="number"
                        onChange={handleOnChange}
                        value={formFields.number}
                        maxLength={10}
                        onInput={(e) => {
                          if(e.target.value.length > e.target.maxLength){
                            e.target.value = e.target.value.slice(0,e.target.maxLength)
                          }
                        }}
                      />{" "}
                      <span className="text-danger">{formError.number}</span>
                    </Form.Group>

                    <button
                      type="submit"
                      className="w-100 btn btn-success py-3 my-5"
                      onClick={handelSubmit}
                    >
                      Enquire Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mobileproductimg">
          <div className="row bg-primary divproductm">
            <div className="col-md-12 text-white ">
              <div className="w-75 m-auto ">
                <h2 className="my-5  font2 text-center">
                  Get to know more about our products
                </h2>
                <div className="mb-4">
                  <input
                    style={{ width: "100%" }}
                    type="text"
                    placeholder="Full Name"
                    className="py-3 px-3"
                    name="fullName"
                    onChange={handleOnChange}
                    value={formFields.fullName}
                  />{" "}
                  <span className="text-danger">{formError.fullName}</span>
                </div>

                <Form.Group className="mb-4">
                  <input
                    style={{ width: "100%" }}
                    type="email"
                    placeholder="Email Id"
                    className="py-3  px-3"
                    name="email"
                    onChange={handleOnChange}
                    value={formFields.email}
                  />{" "}
                  <span className="text-danger">{formError.email}</span>
                </Form.Group>
                <Form.Group className="mb-3">
                  <input
                    style={{ width: "100%" }}
                    type="number"
                    placeholder="Mobile Number"
                    className="py-3  px-3"
                    name="number"
                    onChange={handleOnChange}
                    value={formFields.number}
                  />{" "}
                  <span className="text-danger">{formError.number}</span>
                </Form.Group>

                <button
                  type="submit"
                  className="w-100 btn btn-success py-3 my-5"
                  onClick={handelSubmit}
                >
                  Enquire Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="my-5 ">
        <div className="my-2">
          <FooterFirst />
        </div>
      </div>
      <GoToTopButton />
    </>
  );
}

export default ProductPage;

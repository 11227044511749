import React from "react";

function PageHistory({ Text }) {
  return (
    <div>
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-12 ">
            <span className="text-light fs-5 font1"> Home | </span>
            <span className="text-primary fs-5 font1">
              {Text} 
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageHistory;

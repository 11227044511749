import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import FooterFirst from "../components/footer/FooterFirst";
import InvestoreHero from "../components/Investore/InvestoreHero";
import NavbarFirst from "../components/navs/Navbar";
import image1 from "./../assets/images/investore-about.png";
import image5 from "./../assets/images/investore-img1.png";
import image2 from "./../assets/images/investore-img.png";
import imginvestoreabout from "./../assets/images/imginvestoreabout.png";
import heroimage from "./../assets/images/hero-investore.png";
import { ToastContainer, toast, Zoom } from "react-toastify";
import Aos from "aos";
import "aos/dist/aos.css";
import GoToTopButton from "../components/footer/GoToTopButton";
import { useState } from "react";
import ApiService from "../service/ApiService";
import { Link, useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
function InvestorePage() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(location?.state?.one, location?.state?.two);
  }, [location?.state?.two]);

  useEffect(() => {
    Aos.init({});
  }, [1000]);

  const [data, dataSet] = useState([]);
  useEffect(() => {
    async function fetchMyAPI() {
      try {
        let response = await ApiService.getData("/category/reports");

        if (!response.error) {
          dataSet(response);
        } else {
          console.log(" Blog API Not Working");
        }
      } catch (err) {
        console.error(err.message);
      }
    }
    fetchMyAPI();
  }, []);

  const [dataFAQ, setDataFAQ] = useState([]);
  useEffect(() => {
    async function fetchMyAPI() {
      try {
        let response = await ApiService.getData("/category/faq");

        if (!response.error) {
          setDataFAQ(response);
        } else {
          console.log(" Blog API Not Working");
        }
      } catch (err) {
        console.error(err.message);
      }
    }
    fetchMyAPI();
  }, []);

  const [formFields, setFormFields] = useState({
    fullName: "",
    email: "",
    number: "",
  });

  const [formError, setFormError] = useState({
    fullName: "",
    email: "",
    number: "",
    message: "",
  });

  const handleValidation = () => {
    // const regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    const regText = /[A-Za-z]/;
    const rangEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const newErros = {};
    const { fullName, email, number, message } = formFields;
    if (!fullName) {
      newErros.fullName = "Please Enter Full Name";
    } else if (fullName && !regText.test(fullName)) {
      newErros.fullName = "Full Name should be text";
    } else if (fullName && fullName.length > 50) {
      newErros.fullName = "FullName should be below 50 charecters";
    }

    if (!email) {
      newErros.email = "Please Enter Email";
    } else if (email && !rangEmail.test(email)) {
      newErros.email = "Please Enter Corect Email";
    }

    if (!number) {
      newErros.number = "please enter mobile number";
    } else if (number && number.length != 10) {
      newErros.number = "Mobile Number should be 10 digits";
    }

    return newErros;
  };

  const handleOnChange = (e) => {
    const p = { ...formFields };
    p[e.target.name] = e.target.value;
    setFormFields(p);
    setFormError({
      ...formError,
      [e.target.name]: null,
    });
  };

  const handleSubmit = async () => {
    const handleValidationObject = handleValidation();
    if (Object.keys(handleValidationObject).length > 0) {
      setFormError(handleValidationObject);
    } else {
      try {
        const payload = {
          name: formFields.fullName,
          email: formFields.email,
          mobile_no: formFields.number,
          category: "joinus",
        };
        const addContact = await ApiService.postData("/create_user", payload);

        if (!addContact.error) {
          console.log(addContact, "addContact");
          const arr = { ...formFields };
          arr.fullName = "";
          arr.email = "";
          arr.number = "";
          setFormFields(arr);
          toast.success("Success");
        } else {
          console.log("Not Working");
          toast.error("API Not Working");
        }
      } catch (err) {
        console.error(err.message);
        toast.error(err.message);
      }
    }
  };

  const pdfDownlod = async (e) => {
    console.log(e.target);
  };

  return (
    <>
      <Helmet>
        <title>Eugia Pharma - Investore</title>
        <meta
          name="description"
          content="Eugia is one of the leading pharamceuticals globally. Meet our promoters whose unparalleled experience and faith has helped us deliver great impact constantly "
        />
      </Helmet>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        transition={Zoom}
        delay={1000}
        limit={1}
      />
      <NavbarFirst />
      <div className="herodiv">
        <img
          src={heroimage}
          alt="hero Img"
          width="100%"
          className="img-fluid"
        />
        <div class="aboutus-div">
          <div className="conatiner">
            <h2 className="hero-text fs-1 font2">Investors</h2>
          </div>
        </div>
      </div>
      <div>
        <div className="container mt-4">
          <div className="row">
            <div className="col-md-12 ">
              <span className=" text-light fs-5 font1"> Home | </span>
              <span className="text-primary fs-5 font1"> Investors </span>
            </div>
          </div>
        </div>
      </div>
      <div className="container " data-aos="fade-up" data-aos-duration="3000">
        <div className="row my-4 py-4 investordiv ">
          <div className="col-md-6 ">
            <Image
              src={imginvestoreabout}
              className="invester-about-img img-fluid"
            />
          </div>
          <div className="col-md-6" data-aos="fade-up">
            <div className="border-investore"></div>
            <div>
              <p className=" fs-3 font-weight-bold text-primary font2 my-2 w-75 investoretext1">
                We are a leading healthcare organization and we believe that is
                because our promoters put a lot of faith in our vision, our
                products and our processes. Focused geographies and diversified
                product portfolios help us deliver great impact constantly.
              </p>
              {/* <p className="py-3 my-3 font1">
              </p> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container ">
        <div className="row my-3 py-2">
          <h2 className="text-center text-primary  font2 fs-1 mb-4 pb-4">
            Our Promoters
          </h2>
        </div>
        <div className="row">
          <div
            className="col-md-6 px-5 promoterdivmobile"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <div className="border-investore"></div>
            <div className="mt-2">
              <h3 className="display-6  font-weight-bold text-primary font2 my-2">
                Mr. P. V. Ram Prasad Reddy
              </h3>
              <p className="py-3 my-3 font1">
                Mr. P.V. Ram Prasad Reddy is having 35+ years of experience in
                the industry and holds a degree in Commerce. Mr. Reddy is
                leading the strategic planning of Aurobindo Pharma and is a
                Promoter and Non-executive Director of Aurobindo Pharma. Mr.
                Reddy is also a promoter of Eugia Pharma Specialties Limited
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <Image src={image5} className="w-100  h-100 img-fluid" />
          </div>
        </div>
      </div>
      <div className="container my-5 py-5">
        <div className="row prometarsdiv">
          <div className="col-md-6 ">
            <Image src={image1} className="w-100  h-100 img-fluid" />
          </div>
          <div className="col-md-6 px-5 promoterdivmobile">
            <div className="border-investore"></div>
            <div className="mt-2">
              <h3 className="display-6  font-weight-bold text-primary font2 my-2">
                Mr. K. Nityananda Reddy
              </h3>
              <p className="py-3 my-3 font1">
                Mr. K. Nityananda Reddy, holds a Master’s Degree in Science
                (Chemistry). He has 35+ years of experience in the industry.
                Having a deep knowledge in manufacturing technology. Mr. Reddy
                is associated with Eugia Pharma Specialties Limited as a
                promoter of the company
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        className="container border p-4"
        data-aos="fade-up"
        data-aos-duration="3000"
      >
        <div className="row">
          <h3 className="text-center text-primary font2 fs-1">
            Annual Reports
          </h3>
        </div>

        <div className="row  ">
          {data.map((ele) => (
            <>
              <div className="col-md-4 div-investore">
                <div className="p-3 m-auto">
                  <h5 className="text-primary font1">
                    <b>{ele?.title}</b>{" "}
                  </h5>
                  <>
                    <p
                      className="text-success cursor mt-3 fs-5 my-2 font1"
                      onClick={(e) => pdfDownlod(e)}
                    >
                      <a href={ele?.image} target="_balnk" className="mt-2">
                        Download
                      </a>
                      <img src={pdf} className="mx-2" />
                    </p>
                  </>
                </div>
              </div>
            </>
          ))}
          {console.log(data, "PDF =====>")}
        </div>
      </div> */}
      <div className="container ">
        <div className="row">
          <div className="col-md-12">
            <Image src={image2} className="w-100 img-fluid" />
          </div>
        </div>
      </div>
      <div className="">
        <div className="mt-5 pt-5">
          <div className="container bg-investore">
            <div className="row pt-3">
              <h1 className="text-center text-white py-3  my-2 mt-3 font2">
                {/* Join us on our journey. Become a proud investor today */}
                Know More
                {/* <p>
                
                  Eugia places its investors at the centre of all its
                  operations, making it the pharma company that people keep
                  coming back loyally to. Focused geographies and the
                  differentiated product portfolio help influence the market and
                  price, delivering great impact. 
                </p> */}
              </h1>
            </div>
            <div className="row  m-auto">
              <div className="col-md-4 bg-1 ">
                <input
                  type="text"
                  placeholder="Full Name"
                  className="input-field form-control"
                  name="fullName"
                  onChange={handleOnChange}
                  value={formFields.fullName}
                />{" "}
                <span className="text-danger">{formError.fullName}</span>
              </div>
              <div className="col-md-4 bg-1">
                <input
                  type="text"
                  placeholder="Email ID"
                  className="input-field form-control"
                  name="email"
                  onChange={handleOnChange}
                  value={formFields.email}
                />{" "}
                <span className="text-danger">{formError.email}</span>
              </div>
              <div className="col-md-4 bg-1">
                <input
                  type="number"
                  placeholder="Contact"
                  className="input-field form-control"
                  name="number"
                  onChange={handleOnChange}
                  value={formFields.number}
                  id="numbers-input"
                  maxLength={10}
                  onInput={(e) => {
                    if(e.target.value.length > e.target.maxLength){
                      e.target.value = e.target.value.slice(0,e.target.maxLength)
                    }
                  }}
                />{" "}
                <span className="text-danger">{formError.number}</span>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12 text-center">
                <button
                  type="submit"
                  class="btn btn-success mt-5  py-2 fs-4 mb-5 w-25"
                  onClick={handleSubmit}
                >
                  SUBMIT
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container" style={{ display: "none" }}>
        <div className="row">
          <div className="col-md-12">
            <h3 className="text-primary fs-1 my-5">
              {" "}
              <b> Investors FAQ’s</b>{" "}
            </h3>
          </div>
        </div>

        {dataFAQ.map((ele) => (
          <>
            <details>
              <summary className="text-primary">
                {ele?.title}{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </summary>
              <div class="details-info">
                <p
                  className="text-primary"
                  dangerouslySetInnerHTML={{
                    __html: ele?.description,
                  }}
                ></p>
              </div>
            </details>
          </>
        ))}
      </div>
      <div className="my-3 py-3 mt-5">
        <FooterFirst />
      </div>{" "}
      <GoToTopButton />
    </>
  );
}

export default InvestorePage;

import React, { useState } from 'react';

const CountryDetails = ({ countries }) => {
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedPhone, setSelectedPhone] = useState('');
  const [selectedFax, setSelectedFax] = useState('');

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setSelectedCountry(selectedCountry);
    const countryDetails = countries.find(([country]) => country === selectedCountry);
    if (countryDetails) {
      const [_, phone, fax] = countryDetails;
      setSelectedPhone(phone);
      setSelectedFax(fax || '');
    } else {
      setSelectedPhone('');
      setSelectedFax('');
    }
  };

  return (
    <div>
      <table className="work-table">
        <thead className="table-header">
          <tr>
            <th>Country</th>
            <th>Phone</th>
            <th>Fax</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="table-data">
              <select id="countries" onChange={handleCountryChange} value={selectedCountry} className='country-box'>
                <option value="" className="table-data">Select Country</option>
                {countries.map(([country]) => (
                  <option key={country} value={country} className="table-data2">
                    {country}
                  </option>
                ))}
              </select>

            </td>
            <td className="table-data">{selectedPhone ? selectedPhone : '-'}</td>
            <td className="table-data">{selectedFax ? selectedFax : '-'}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default CountryDetails;